<template>
  <div class="menu-container" v-draggable>
      <div class="menu">
      <h4 style="text-align: center; margin:13px 0px;">修改文件</h4>
      <form>
        <div class="form-group">
          <table>
            <tr>
              <td style="width:85px;">文件名称：</td>
              <td>
                <input type="text" id="project-name" v-model="approvingFile.filename" placeholder="文件名称"
                  autocomplete="off" style="width:457px;"  class="borderstyle">
              </td>
            </tr>
            <tr>
              <td style="width:85px;">替换文件：</td>
              <td>
                <input type="file"  @change="handlePubFileUpload($event)" style="margin-top:5px;" ref="fileInput"/>
              </td>
            </tr>
          </table>
        </div>
      </form>
      <br>
      <div class="form-group">
        <table>
          <tr>
            <td style="width:85px;">
              修改意见:
            </td>
            <td>
              <textarea name="" id="" cols="30" rows="6" placeholder="请输入审批意见。。。"  class="borderstyle"
                style="width: 455px;margin: 0 5px;" v-model="newPubFileSummary">
              </textarea>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="menu-buttons">
                <button type="button" @click="submitModifyFile">提交</button>
                <button type="button" @click="resetForm" style="margin-left: 10px">取消</button>
              </div>
            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { Row } from 'element-ui'
import Vue from 'vue'
import { mapState, mapMutations, mapActions} from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
// import qs from 'qs'
// 以下是移动弹出菜单的代码
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {
        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset

        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})
// 以上是移动弹出菜单的代码
export default {
    name: "ModifyFileMenu",
    data() {
        return {
            pubFileName: '',
            projectName: "",
            pubProjectIdfp:'',
            projectType: "type1",
            projectFolder: null,
            pubFileApprover:'',
            pubFileChecker:null,
            pubFileCheckers:[],
            pubProjectName:'',
            pubProjects:[],
            pubProjectParters:[],
            pubProject:null,
            pubFileFolder:'',
            myProjectlist: [],
            projects:'',
            username:'',
            saveOrNot:true,
            newPubFile:null,
            newPubFileSummary:'',
            approvingFileName:'',
            uploadFile: null,
            selectedUsername:'',
            checkboxes: [false, false, false],
            inputnum: 0,
            newUploadFile:null,
        };
    },
    computed: {
      folders: function(){
        const myfolder=[]
        const myfolderobj={}
        var Id=1
        for(var i=0; i<this.myProjectlist.length;i++) {
          if(!myfolderobj[this.myProjectlist[i].groupname]) {
            myfolderobj[this.myProjectlist[i].groupname]=i+1
            const folder={id: Id, name: this.myProjectlist[i].groupname}
            myfolder.push(folder)
            Id++
          }
        }
        return myfolder
      },


      pubProjects1:function(){
        if(this.projectFolder==null){
          return this.myProjectlist
        } else {
          const projects=this.myProjectlist.filter(project=>project.groupname==this.pubFileFolder)
          return projects
        }
     },


    },
    watch :{
      saveOk: function(){
        this.saveOrNot=!this.saveOk
      },
      projectFolder: function(){
        this.pubProjects=this.myProjectlist.filter(project=>project.groupname==this.projectFolder)
      },
    },
    props: ['menuVisible','approvingFile'],
    methods: {
      // closeMenu(){
      //   this.$emit('closeMenu')
      //   this.switchMenuMask()
      // },
      ...mapMutations({
          switchMenuMask: "switchMenuMask",
          updateSelectProjName: "updateSelectProjName"
      }),
      ...mapActions({
        getProjectAclListByIdfp:'getProjectAclListByIdfp',
        updatePubfileByIdff:'updatePubfileByIdff',
        addChatmsg:'addChatmsg'
      }),
      addFolder() {
          const folderName = prompt("请输入文件夹名称");
          if (folderName) {
              const newFolder = { id: this.folders.length + 1, name: folderName };
              this.folders.push(newFolder);
              this.projectFolder= newFolder;
          }
      },

      changeApprovingFileName(e){
        console.log(e.target.value)
      },

      computedPubProject(){
        
        this.pubProject=this.pubProjects.find(item=>item.name==this.pubProjectName)
     
        this.getProjectAclListByIdfp(this.pubProjectIdfp)
          .then((result)=>{
            console.log('this.pubProject',result)
            this.pubProjectParters=result
          })
      },

      getPartersFromProject(idfp){

      },

      handlePubFileUpload(e){
        // let file = e.target.files[0];
        this.newUploadFile= e.target.files[0]
        this.approvingFile.filename=this.newUploadFile.name
        // const username=localStorage.getItem('username')
        
        // const idff=uuidv4()
        // const fileSizeInKB = (file.size / 1024).toFixed(2) + " KB"
        // this.pubFileName = file.name
        // this.newPubFile={idff:idff, project:'',folder:this.projectFolder,filename:this.pubFileName,publisher:username,
        //   approver: '',pubtime:'', size: fileSizeInKB, downloadtimes:0,summary:this.newPubFileSummary
        // } 
        // this.approvingFile=e.target.files[0]
        // if(this.approvingFile){
        //     const xx=await this.handleFileUpload1(this.uploadFile)
        //     newPubFile.url=xx
        //   }else {
        //     newPubFile.url=''
        //   }
        

        
      },

      methodPubFileCheckers(){
        console.log('pubfilecheckers',this.pubFileCheckers)
      },

      // async pubNewFile(){
      //   if(this.uploadFile){
      //     const username=localStorage.getItem('username')
      //     const pubtime=this.convertDateFormat(new Date())
      //     const idff=uuidv4()
      //     const fileExtension = this.pubFileName.split('.').pop()
      //     const checkerStr = this.pubFileCheckers.join(';')
      //     this.newPubFile.pubtime=pubtime
      //     this.newPubFile.filename=this.pubFileName      
      //     this.newPubFile.summary=this.newPubFileSummary
      //     this.newPubFile.type=fileExtension
      //     this.newPubFile.publisher=username
      //     this.newPubFile.downloadtimes=0
      //     this.newPubFile.subscriber={}
      //     this.newPubFile.project=this.pubProjectIdfp
      //     this.newPubFile.folder=this.projectFolder
      //     this.newPubFile.idff=idff
      //     this.newPubFile.approver=this.pubFileApprover
      //     this.newPubFile.checker=checkerStr
      //     this.newPubFile.filestatus='待审核'
      //     this.newPubFile.checked=0
      //     this.newPubFile.approved=0
      //     const file=this.$refs.fileInput.files[0]
      //     const xx=await this.handleFileUpload1(file)
      //     this.newPubFile.url=xx
      //     this.$emit("pubNewFile",this.newPubFile)
      //     console.log('发布',this.newPubFile)
      //   }

      // },

      handleCheckboxChange(index){
        this.checkboxes = this.checkboxes.map((value, i) => {
          return i === index ? true : false;
        });
        this.inputnum = index + 1;

        switch(index){
          case 0:{
            this.newPubFileSummary='同意。'
            break;
          }
          case 1:{
            this.newPubFileSummary='请修改后重新提交。'
            break;
          }
          case 2: {
            this.newPubFileSummary='不同意。'
            break;
          }
          case 3:{
            this.newPubFileSummary='请协助审批。'
            break;
          }

        }


      },

      async submitModifyFile(){
        var pubFile=this.approvingFile
        //还有一个错误，就是不能审批时重新上传文件
        if(this.newUploadFile){
            const xx=await this.handleFileUpload1(this.newUploadFile)
            pubFile.url=xx
          }else {


          }
        // pubFile.filestatus='待审核'
        const checkers=pubFile.checker.split(';')
        const username=localStorage.getItem('username')
        const checkeders=pubFile.checked.split(';')
        if(checkers.length==checkeders.length){
          pubFile.filestatus='待审批'
        } else {
          pubFile.filestatus='待审核'
        }
        const fileMsg={
          id:uuidv4(),
          receiver:pubFile.idff,
          sender:`checker|${username}`,
          timestamp: new Date(),
          file_name: pubFile.filename,
          message:this.newPubFileSummary,
          type:'text',
          img_name:'',
          file_size:0,
          aboutwhat:'fileprocess'
        }

        fileMsg.timestamp=this.convertDateFormat(fileMsg.timestamp)
        pubFile.pubtime=this.convertDateFormat(pubFile.pubtime)

        this.updatePubfileByIdff(pubFile)
          .then((result)=>{
            this.addChatmsg(fileMsg)
            this.switchMenuMask()
            // this.$emit("closeMenu");
          })

        this.$emit('modifyPubFile',fileMsg,pubFile)
      },

      //同意审批文件的函数
      agreeApproveFile(pubFile){
        //1.判断我是不是文件的审批人，或者已经通过其他模块筛选出我没审批的文件，则这里默认我是审批人，且还没审批
        //2. 判断我是审核人还是审批人
        console.log('审批后的pubfile1',pubFile)
        const checkers=pubFile.checker.split(';')
        const approver=pubFile.approver
        const username=localStorage.getItem('username')
        //以下判断我是审核人还是审批人
        if(username==approver){
          pubFile.approved=username
          pubFile.filestatus='已审批'
        } else if (checkers.includes(username)) {
          var newChecked=`${username};${pubFile.checked}`
          if (newChecked.endsWith(';')) {
            newChecked = newChecked.slice(0, -1); // 使用slice方法去除最后一个字符
          }
          pubFile.checked=newChecked
        } else {
          return
        }
        pubFile.pubtime=this.convertDate(pubFile.pubtime)
        // this.updatePubfileByIdff(pubFile)
        console.log('审批后的pubfile',pubFile)
      },

          //把标准时间转换成普通的字符串时间
      convertDate(targetDate){
        const date = new Date(targetDate);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        
        const formattedDate = `${year}/${month}/${day}`;
        return formattedDate
        
      },

      handleFileUpload(file) {
        if (!file) {
          console.log("请选择要上传的文件");
          return;
        }
        const token = localStorage.getItem('token')
        const formData = new FormData();
        formData.append('file', file);
    
        axios.post('https://api2.itaskid.com' + '/my/uploadpubfile', formData, {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          // console.log(response.data)
          return response.data.url
        })
        .catch(error => {
          console.log(error);
        });
      },

      async handleFileUpload1(file) {
        try {
          if (!file) {
            console.log("请选择要上传的文件");
            return;
          }
          const token = localStorage.getItem('token')
          const formData = new FormData();

          const encodedFileName = encodeURIComponent(file.name);
          formData.append('file', file, encodedFileName);
          const response = await axios.post(
            'https://api2.itaskid.com' + '/my/uploadpubfile',
            formData,
            {
              headers: {
                Authorization: token,
                'Content-Type': 'multipart/form-data'
              }
            }
          );
          // console.log(response.data.data.url)
          return response.data.data.url;
        } catch (error) {
          console.log(error);
        }
      
        // 以上是保存新增的项目
        this.$emit("closeMenu");
        this.switchMenuMask();
      },


      async saveProject() {
        try {
          // 以下是保存新增的项目
          const token = localStorage.getItem('token')
          if (!this.username) {
            // 如果用户未登录，跳转到登录页面
            this.$router.push('/login');
            return;
          }
          const projectinfo = {
            name: this.projectName,
            groupname: this.projectFolder.name,
            charger: this.username,
          };
          // 发送 POST 请求
          const response = await axios({
            method: 'post',
            url: 'https://api2.itaskid.com' + '/my/addproject',
            headers: {
              Authorization: token,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: projectinfo
          });
      
          const newProj = response.data;
          this.$emit('updateLocalItems', newProj);
          this.updateSelectProjName(newProj)
          if (!response.data.status) {
            // 根据需求进行逻辑处理
          } else {
            alert(response.data.message);
          }
        } catch (error) {
          alert(error.response.data.message);
        }
        
        // 以上是保存新增的项目
        this.$emit("closeMenu");
        this.switchMenuMask();
      },
      resetForm() {
          // this.projectName = "";
          // this.projectType = "type1";
          // this.projectFolder = null;
          this.$emit("closeFileModifyMenu");
          this.switchMenuMask();
          

      },
      getUsername() {
        const token = localStorage.getItem('token')
        axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
          headers: { 
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          } // 在 headers 中添加 Authorization Token
        })
        .then(response => {
          this.username = response.data.username;
        })
        .catch(error => {
          console.log(error);
        });
      },

      convertDateFormat(data){
        const date = new Date(data)
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        let hour = date.getHours().toString().padStart(2, '0')
        const minute = date.getMinutes().toString().padStart(2, '0')
        const formattedDate = `${year}/${month}/${day} ${hour}:${minute}`
        return formattedDate
      }
    },
    mounted(){

      

      this.approvingFileName=this.approvingFile.filename

      this.getProjectAclListByIdfp(this.approvingFile.project)
          .then((result)=>{
            this.pubProjectParters=result
          })
      
      this.getUsername();
      const token = localStorage.getItem('token')
      axios.get('https://api2.itaskid.com' + '/my/taskgroup',{
        headers: {
          Authorization: token
        },
      })
        .then((response)=>{
          if (Array.isArray(response.data)){
            const usernogroup=`${this.username}nogroup`
            const usertoday=`${this.username}today`
            const userweek=`${this.username}week`
            this.myProjectlist=response.data.filter(item => 
            item.charger==this.username&&(!(item.idfp==usernogroup)&&!(item.idfp==usertoday)&&!(item.idfp==userweek)))
            this.pubProjects=this.myProjectlist

          }else
          alert('账号未认证，无法获取数据！')
        })
        .catch(function (error) {
          console.log(error);
        })
      
      

    },

    components: { Row }
 }
</script>

<style lang="less" scoped>

.menu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 250, 250, 1);
  width: 600px;
  height: 300px;
  border: 1px solid gray;
  z-index: 100;
  cursor: move;
  form {
    height: auto;
  }

  
  input #project-name {
    width: 410px;
    height: 30px;
    padding: 0;
    margin: 0px 20px 0 20px;
  }
  // input {
  //   width: 405px;
  //   height: 30px;
  //   padding: 0;
  // }
  .form-group {
     width: 600px;
     margin: 10px 0;
     height: 30px;
     padding-left: 20px;

     input {
      height: 30px;
     }


  }
  .menu-buttons {
    margin-right: 5px;
    text-align: right;
    height: 40px;
  }
  button {
    width: 100px;
    height: 30px;
    // margin-left: 5px;
  }
  label {
    width: 200px;
    text-align: left;
  }
  select {
    margin-left: 20px;
    width: 340px;
    height: 30px;
  }
}

.borderstyle {
  border:0.5px solid rgb(207, 210, 216);
  border-radius: 3px;
}

.borderstyle:focus {
  outline: 0.5px solid rgb(111, 181, 254);
  
}









  
</style>