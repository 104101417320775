import { render, staticRenderFns } from "./PlanGroup1.vue?vue&type=template&id=08b6928e&scoped=true"
import script from "./PlanGroup1.vue?vue&type=script&lang=js"
export * from "./PlanGroup1.vue?vue&type=script&lang=js"
import style0 from "./PlanGroup1.vue?vue&type=style&index=0&id=08b6928e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b6928e",
  null
  
)

export default component.exports