import Vue from 'vue'
import VueRouter from 'vue-router'
import Detail from '@/components/task/Detail.vue'
import Login from '@/components/user/Login.vue'
import Home from '@/components/Home.vue'
import TaskProject from '@/components/task/ProjectForSql8.vue'
import TaskGroup from '@/components/task/ProjectGroup.vue'   //修改后
import MyCalendar from '@/components/calendar/MyCalendar.vue'
// import MyPlan from '@/components/MyPlan1.vue'
// import MyFile from '@/components/MyFile.vue'
import MySet from '@/components/myset/MySet.vue'
import Register from '@/components/user/Register2.vue'
import ResetPsw from '@/components/user/ResetPsw.vue'
import FileGroup from '../components/file/FileGroup.vue'
import FilePublish from '../components/file/FilePublish.vue'
import FileTask from '../components/file/FileTask.vue'
import FormPublish from '../components/file/FormPublish.vue'
import FileList from '../components/file/FilePublish.vue'
import FilePrivate from '../components/file/FilePrivate.vue'
import FileMyself from '../components/file/FileMyself.vue'
import FileShare from '../components/file/FileShare.vue'
import FileDetail from '../components/file/FileDetail'
import ShareFileDetail from '../components/file/ShareFileDetail'
import FormDetail from '../components/file/FormDetail'
import PlanGroup from '../components/plan/PlanGroup1.vue'
import ProjectPlan from '../components/plan/ProjectPlan3.vue'
import FriendshipGroup from '../components/message/FriendshipGroup'
import MsgGroup1 from '../components/message/MsgGroup1'
import MsgRecord from '../components/message/MsgRecord'
import ConnectGroup from '../components/connect/ConnectGroup'
import Help from '../components/help/Help'
import UsersManagement from '../components/user/UsersManagement'


Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  props: {},
  routes: [
    { path: '/', redirect: '/login' },
    {
      path: '/login', component: Login,
    },
    {
      path: "/register",
      name: 'Register',
      component: Register
    },
    {
      path: "/resetpsw",
      name: 'ResetPsw',
      component: ResetPsw
    },
    {
      path: '/home', redirect: '/home/taskgroup/project/today'
    },
    {
      path: '/home/taskgroup', redirect: '/home/taskgroup/project/today'
    },
    {
      path: '/home',
      component: Home,
      children: [
        {
          path: 'friendshipgroup', redirect: to => {
            const username = localStorage.getItem('username')
            const keyOfLocalFriend = `user|${username}`
            const localFriendship = JSON.parse(localStorage.getItem(keyOfLocalFriend))
            const id = localFriendship.id
            return `/home/friendshipgroup/msgrecord/${id}`;
          },
          // component: FriendshipGroup, children: [
          component: MsgGroup1, children: [
            {
              path: 'msgrecord/:id', name: 'msgrecord', component: MsgRecord
            }
          ]
        },
        {
          path: 'taskgroup', component: TaskGroup, children: [
            {
              path: 'project/:idfp', name: 'project', component: TaskProject, children: [
                // path: 'project', name: 'project', component: TaskProject, children: [
                {
                  path: 'detail/:idft', name: 'detail', component: Detail, props: true
                }
              ]
            }
          ]
        },
        { path: 'calendar', component: MyCalendar },
        {
          path: 'plan', redirect: '/home/plan/project/today', component: PlanGroup, children: [
            {
              path: 'project/:idfp', name: 'projectplan', component: ProjectPlan
            }
          ]
        },
        {
          path: 'filegroup', redirect: '/home/filegroup/filepublish/all', component: FileGroup, children: [
            {
              path: 'filepublish/:idfp', name: 'filepublish', component: FilePublish, children: [
                { path: 'filedetail/:idff', name: 'filedetail', component: FileDetail, props: true }
              ]
            },
            {
              path: 'filetask/:idfp', name: 'filetask', component: FileTask, children: [
                // { path: 'filedetail/:idftf', name: 'filedetail', component: FileDetail, props: true }
              ]
            },
            {
              path: 'formpublish/:idfp', name: 'formpublish', component: FormPublish, children: [
                { path: 'formdetail/:idff', name: 'formdetail', component: FormDetail, props: true }
              ]
            },
            {
              path: 'fileshare/:idfp', name: 'fileshare', component: FileShare, children: [
                { path: 'sharefiledetail/:idfsf', name: 'sharefiledetail', component: ShareFileDetail, props: true }
              ]
            },
            { path: 'fileprivate/:idfp', name: 'fileprivate', component: FilePrivate },
          ]
        },
        { path: 'setting', component: MySet },
        { path: 'users', component: UsersManagement },
        { path: 'connect', component: ConnectGroup },
        { path: 'help', component: Help }
      ]
    }

  ]
})

//前置导航守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.path.startsWith('/login') || to.path.startsWith('/register')) {
    next()
  } else {
    if (token) {
      next()
    } else {
      // alert('路由全局守卫验证token未通过')
      next({ path: '/login' })
    }
  }
});


export default router