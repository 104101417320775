<template>
  <div style="display: flex;">
    <div  class="el-project" style="width: 250px;border-right: 1px solid rgba(200, 200, 200, 0.9); ">
      <ProjectGroup @selectAllProject="selectAllProject" @selectProjectTasks="selectProjectTasks" @selectGroupTasks="selectGroupTasks"
        @selectNoGroupTasks="selectNoGroupTasks">
      </ProjectGroup>
    </div>
    <div style="width:calc(100vw - 300px);">





      <div style="text-align: left; padding-left:5px;position:relative;width:100%;">
              <!-- 以下是显示选择日期的资源需求计划 v-if="ifShowSelectedDateResPlans" -->
        <div  class="selectedDateResPlanContainer"  v-if="ifShowSelectedDateResPlans">
          <div ID="main1" style="height:400px;margin-top:10px;overflow: auto;">
          </div>
        </div>


        <div style="margin-right:20px;margin-bottom:5px; display:flex;">
          <div>
            <button class="resourceviewbutton" @click="switchResourceView(1)"  style="width: 45px;"
              :class="{resOptionButton: resViewOption === 1 }">
              全部
            </button>
            <button class="resourceviewbutton" @click="switchResourceView(2)"  style="width: 45px;"
            :class="{resOptionButton: resViewOption === 2 }">
              人
            </button>
            <button class="resourceviewbutton" @click="switchResourceView(3)"  style="width: 45px;"
              :class="{resOptionButton: resViewOption === 3 }">
              料
            </button>
            <button class="resourceviewbutton" @click="switchResourceView(4)"  style="width: 45px;"
              :class="{resOptionButton: resViewOption === 4 }">
              机
            </button>
            <button class="resourceviewbutton" @click="switchResourceView(5)" style="width: 45px;"
              :class="{resOptionButton: resViewOption === 5 }">
              费用
            </button>
            <select v-model="resResourceTtile" style="outline:none;width:125px;border:none; margin-left:50px;
              font-weight:bold;font-size:14px;text-align:center;">
              <option value="资源需求计划表">资源需求计划表</option>
              <option value="资源需求曲线图">资源需求曲线图</option>
              <option value="资金需求计划表">资金需求计划表</option>
              <option value="资金需求曲线图">资金需求曲线图</option>
            </select>
          </div>
        </div>
        <div v-show="resResourceTtile === '资源需求计划表'||resResourceTtile === '资金需求计划表'" style="display:flex; height:calc(100vh - 115px);">
          <div  class="text1" style="width:335px;"
            ref="leftrespname">
            <table>  
              <thead @contextmenu.prevent="timeRulerEditMenu($event)" class="table-thead">
                <tr  style="height: 51.5px;">
                  <td style="min-width:50px;border-bottom: none;" rowspan="2">序号</td>
                  <td style="min-width:150px;border-bottom: none;" rowspan="2">资源名称</td>
                  <td style="min-width:75px;border-bottom: none;" rowspan="2">资源类型</td>
                  <td style="min-width:50px;border-bottom: none;" rowspan="2">单位</td>
                </tr>
              </thead>
              <EditProjectMenu 
              v-if="showTimeRulerEditMenu"
              :projeditMenuitems="timeRulerEditMenuitems" 
              :event="event" 
              :parentNode="parentNode"
              @projeditMenuitemClick="timeRulereditMenuitemClicked">
              </EditProjectMenu>
              <EditTimeRuler
                v-if="showEditTimeRuler"
                @cancelEditPro="cancelEditPro"
                @closeEditProj="closeEditProj">
              </EditTimeRuler>
              <tbody>
                <!-- <tr v-for="(res,index) in selectMergedResPlanArray" :key="index"> -->
                <tr v-for="(res,index) in computedselectResPlanArray()" :key="index">
                  <td style="min-width:50px;height:25px;padding:0 2px;">{{ index+1 }}</td>
                  <td style="min-width:150px;height:25px; padding:0 2px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ res.resname }}</td>
                  <td style="min-width:75px;height:25px;padding:0 2px;">{{ res.restype }}</td>
                  <td style="min-width:50px;height:25px;padding:0 2px;">{{ res.resunit }}</td>
                </tr>
                <tr v-show="showResNumOrMoneyNum">
                  <td style="min-width:50px; height:25px; padding:0 2px;border-bottom: 0.5px solid grey;" rowspan="2">
                    合计
                  </td>
                  <td style="min-width:225px; height:25px; padding:0 2px;text-align: center;" colspan="2">劳动力</td>
                  <td style="min-width:50px;height:25px; padding:0 2px;">人</td>
                </tr>
                <tr v-show="showResNumOrMoneyNum">
                  <td style="min-width:225px; height:25px; padding:0 2px;text-align: center; border-bottom: 0.5px solid grey;" colspan="2">设备</td>
                  <td style="min-width:50px; height:25px; padding:0 2px;border-bottom: 0.5px solid grey;">台</td>
                </tr>
                <tr v-show="!showResNumOrMoneyNum" style="border-bottom: 0.5px solid grey;">
                  <td style="min-width:50px; height:25px; padding:0 2px;border-bottom: 0.5px solid grey;">
                    合计
                  </td>
                  <td style="min-width:225px; height:25px; padding:0 2px;text-align: center;" colspan="2">资金</td>
                  <td style="min-width:50px; height:25px; padding:0 2px;">元</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="width:calc(100vw - 680px); overflow-x:auto;"   class="text" ref="rightrespreq"  @scroll="syncScroll">
            <table>  
              <thead @contextmenu.prevent="timeRulerEditMenu($event)"  class="table-thead">
                <tr  style="height: 25px;position: sticky;line-height: 25px;">
                  <td v-for="item in monthArray" :key="item.month" :colspan="item.days"
                    style="padding:0 2px;height:25px; max-height:25px;font-weight:bold;text-overflow: ellipsis; white-space: nowrap;">
                     <label>{{ item.month }}</label>
                  </td>
                </tr>
                <tr  style="height: 25px;overflow-x:auto;">
                  <td v-for="item in dayArray" :key="item.month" @click="inputDate(item)"
                    style="padding:0 2px;height:25px; min-width: 20px;max-width:20px;">
                    {{ item.day }}
                  </td>
                </tr>
              </thead>
              <EditProjectMenu 
              v-if="showTimeRulerEditMenu"
              :projeditMenuitems="timeRulerEditMenuitems" 
              :event="event" 
              :parentNode="parentNode"
              @projeditMenuitemClick="timeRulereditMenuitemClicked">
              </EditProjectMenu>
              <EditTimeRuler
                v-if="showEditTimeRuler"
                @cancelEditPro="cancelEditPro"
                @closeEditProj="closeEditProj">
              </EditTimeRuler>  
              <tbody v-show="showResNumOrMoneyNum">
                <!-- <tr v-for="(res,index) in selectMergedResPlanArray" :key="index" style="height:25px;line-height:25px;"> -->
                <tr v-for="(res,index) in computedselectResPlanArray()" :key="index" style="height:25px;line-height:25px;">
                  <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;
                    padding:0 2px;height:25.84px;"
                    @click="inputTask(selectedTask,item)" :class="{ ResorceBackground: isWorkDay(selectedTask,item)}">
                    {{ computedSelectResPlanSum(res.resname,item.date) }}
                  </td>
                </tr>
                <tr>
                  <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;
                    padding:0 2px;height:25.84px;"
                    @click="inputTask(selectedTask,item)">
                    <!-- {{ computedAllResorceNum(item).workernum }} -->
                    {{ computedDayWorkerNum(item.date) }}
                  </td>
                </tr>
                <tr>
                  <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;
                    padding:0 2px;height:25.84px; border:solid 0.5px grey;"
                    @click="inputTask(selectedTask,item)">
                    {{ computedEquipmentNum(item.date) }}
                  </td>
                </tr>
              </tbody>
              <tbody v-show="!showResNumOrMoneyNum">
                <!-- <tr v-for="(res,index) in selectMergedResPlanArray" :key="index" style="height:25px;line-height:25px;"> -->
                <tr v-for="(res,index) in computedselectResPlanArray()" :key="index" style="height:25px;line-height:25px;">
                  <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;
                    padding:0 2px;height:25.84px;"
                    @click="inputTask(selectedTask,item)" :class="{ ResorceBackground: isWorkDay(selectedTask,item)}">
                    {{ computedSelectResCostPlanSum(res.resname,item.date) }}
                  </td>
                </tr>
                <tr style="border-bottom: 0.5px solid grey;">
                  <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;
                    padding:0 2px;height:25.84px;"
                    @click="inputTask(selectedTask,item)">
                    <!-- {{ computedAllResorceNum(item).workernum }} -->
                    {{ computedDayCost(item.date) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="ifShowSelectedTaskResource">
          <div v-for="(res,index) in convertStringToResourceArray(this.selectedTask)" :key="index" class="text">
            <table> 
              <tr>
                <td style="min-width:50px;">{{ index+1 }}</td>
                <td style="min-width:100px;">{{ res.resname }}</td>
                <td style="min-width:50px;">{{ res.unitotsk }}</td>
                <td v-for="item in dayArray" :key="item.month"  style="text-align: center; min-width: 20px;max-width:20px;
                  border-bottom:none; padding:0 2px;height:25px;"
                  @click="inputTask(selectedTask,item)" :class="{ ResorceBackground: isWorkDay(selectedTask,item)}">
                  {{computedResorceNum1(res,item)}}
                  <!-- {{ res.num }} -->
                </td>
              </tr>
            </table>
          </div>              
        </div>
      </div>
      <div v-show="resResourceTtile === '资源需求曲线图'||resResourceTtile === '资金需求曲线图'"  style="text-align: left; padding-left:5px;">
        <div style="text-align: left;margin-top:10px;">
          <el-cascader @change="handleCascaderChange"
              placeholder="试试搜索：人员" size="small"
              :options="computedSelectResNameArray()"
              filterable>
            </el-cascader>
        </div>
        <div>
          <div ID="main" style="height:400px;margin-top:30px;">
           </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import { mapState,mapMutations,mapActions} from 'vuex'
import { eventBus } from '../../main.js'
import ProjectGroup from '../pubcomponets/ProjectGroup.vue'
import EditProjectMenu from '../menu/task/EditProjectMenu.vue'
import EditTaskMenu from '../menu/calendar/EditTaskMenu.vue'
import AddTaskMenu from '../menu/task/AddTaskMenu.vue'
import Detail from '../task/Detail.vue'
import io from 'socket.io-client'

import EditTimeRuler from '../menu/help/EditTimeRuler.vue'

// 自定义draggable指令
Vue.directive('draggable', {
  bind(el, binding, vnode) {
    let isDragging = false
    let currentX
    let currentY
    let initialX
    let initialY
    let xOffset = 0
    let yOffset = 0

    document.addEventListener('mousedown', dragStart)
    document.addEventListener('mouseup', dragEnd)
    document.addEventListener('mousemove', drag)

    function dragStart(e) {
      if (e.target === el) {

        initialX = el.offsetLeft
        initialY = el.offsetTop

        xOffset = e.clientX - initialX
        yOffset = e.clientY - initialY

        el.style.position = 'fixed'

        isDragging = true
      }
    }

    function dragEnd(e) {
      isDragging = false
    }

    function drag(e) {
      if (isDragging) {
        e.preventDefault()

        currentX = e.clientX - xOffset
        currentY = e.clientY - yOffset
        el.style.left = `${currentX}px`
        el.style.top = `${currentY}px`
      }
    }
  }
})


export default {
  components: {
    ProjectGroup,
    EditTaskMenu,
    Detail,
    AddTaskMenu,
    EditProjectMenu,
    EditTimeRuler
  },
  data() {
    return {


      resNameOption:[],
      resViewOption:1,
      monthArray:[],
      dayArray:[],
      showTimeRulerEditMenu:false,
      timeRulerEditMenuitems:[],
      parentNode: null,
      showEditTimeRuler:false,
      ifShowAllTaskResource:true,
      ifShowSelectedTaskResource:false,
      showResNumOrMoneyNum:true,


      currentMonth: '',
      days: [],
      showBeforeNoon:true,
      thisWeekDays:[],
      weeks:['周日','周一','周二','周三','周四','周五','周六'],
      months:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月',],
      dayTimes1:['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00'],
      dayTimes2:['12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'],
      selectedDate: null,
      currentDay: new Date(),
      selectedTasks:[
        {name:'明天上午九点开会',starttime:'2023-10-08'},
        {name:'嘻嘻哈哈吼吼悦悦',starttime:'2023-10-08'},
      ],
      allTasks:[],
      tasklistall:[],
      showYearMonthMenu:false,
      currentYear:'',
      selectedIndex: 0,
      selectTasks:[],
      username:'',
      showMonthCalendar:true,
      showWeekCalendar: false,
      showDayCalendar: false,
      calendarOption:3,
      weekDates:[],

      //日、周、月的选项
      selectedOption:'',
      hours:['00','01','02'],
      showTaskEdit:false,

      //与点击任务编辑相关的信息currentDay
      selectedTask:{},
      // taskMessages:[],
      selectedProjectAcls:[],
      selectedProject:{},
      myTaskAcllist:[],
      taskContents:[],
      socket: null,
      ifShowAddTaskMenu:false,
      newStartDate:'',
      selectedDateTime:'',
      newTime:'',
      myAllTaskResPlans:[],
      selectProjectResPlans:[],
      resResourceTtile:'资源需求计划表',
      allMyProjectsResPlansByDate:[],
      selectProjectsResPlansByDate:[],
      allResReqDuration:null,
      selectProjectResReqDuration:null,
      selectMergedResPlanArray:[],

      chartDayArray:[],
      chartSeriesArray:[],
      chartKeys:[],
      myChart:{},
      resPlanChartTitle:{},

      selectedDayResPlan:null,
      ifShowSelectedDateResPlans:false,

      selectedDateResPlansChart:null,

    };
  },
  mounted() {

    const username=localStorage.getItem('username')
    this.username=username




    let tasklistAll=[]

    // // 创建 WebSocket 客户端实例并连接服务器  
    // this.socket = new WebSocket("wss://api2.itaskid.com");  
    
    // // 监听连接成功事件  
    // this.socket.onopen = () => {  
    //   console.log('Connected to WebSocket server');  
    // };  
    
    // // 监听服务器发送的消息  
    // this.socket.onmessage = (event) => {  
    //   const message = JSON.parse(event.data); // 解析收到的消息  
    //   if (message.receiver == this.selectedTask.idft) {  
    //     this.taskContents.push(message);  
    //   }  
    // };  
    
    // // 监听连接关闭事件（可选）  
    // this.socket.onclose = () => {  
    //   console.log('WebSocket disconnected');  
    // };  
    
    // // 监听错误事件（可选）  
    // this.socket.onerror = (error) => {  
    //   console.error('WebSocket error:', error);  
    // };

    this.socket = io("https://api2.itaskid.com"); 
    
    // 监听连接成功事件
    this.socket.on('connect', () => {
      console.log('Connected to socket.io server');
      
    });

    
    
    // 监听服务器发送的消息
    this.socket.on('message', (message) => {
      if(message.receiver==this.selectedTask.idft){
        this.taskContents.push(message)
      }
    }); 

    this.fetchTasks()
      .then((res)=>{
        this.selectAllProject()
      })

  },

  computed :{

    computedSelectResNameArray(){
      return ()=>{
        let resNameOption=[]
        let workerArray=[{value:'全部',label: '全部工人'}]
        let equipmentArray=[{value:'全部',label: '全部设备'}]
        let materialArray=[{value:'全部',label: '全部材料'}]
        let moneyArray=[{value:'全部',label: '全部资金'}]
        this.selectMergedResPlanArray.forEach((item)=>{
          if(item.restype=='工人'){
            workerArray.push({value:item.resname,label:item.resname})
          }else if(item.restype=='设备'){
            equipmentArray.push({value:item.resname,label:item.resname})
          }else if(item.restype=='材料'){
            materialArray.push({value:item.resname,label:item.resname})
          }else if(item.restype=='资金'){
            moneyArray.push({value:item.resname,label:item.resname})
          }
        })

        resNameOption.push({value:'工人',children:workerArray,label:'工人'})
        resNameOption.push({value:'设备',children:equipmentArray,label:'设备'})
        resNameOption.push({value:'材料',children:materialArray,label:'材料'})
        resNameOption.push({value:'资金',children:moneyArray,label:'资金'})
 
        return resNameOption
      }
    },



    computedAllResorceNum(){
      return (item)=>{
        let workerNum=0
        let equipmentNum=0
        item.resourcearray.forEach(res=>{
          if(res.resunit=='人'){
            workerNum += res.num;
          } else if(res.resunit=='台') {
            equipmentNum += res.num
          } else {

          }
        })

        return {workernum:workerNum,equipmentnum:equipmentNum}
      }
    },

    computedselectResPlanArray(){
      return ()=>{
        if (!this.selectMergedResPlanArray) {
          return [];
        }

        if(this.resViewOption==1){
          return this.selectMergedResPlanArray
        } else if (this.resViewOption==2){
          const ledx=this.selectMergedResPlanArray.filter(item=>{
            return item.restype=='工人'
          })
          return ledx
        } else if (this.resViewOption==3){
          const ledx=this.selectMergedResPlanArray.filter(item=>{
            return item.restype=='材料'
          })
          return ledx
        } else if (this.resViewOption==4){
          const ledx=this.selectMergedResPlanArray.filter(item=>{
            return item.restype=='设备'
          })
          return ledx 
        } else if (this.resViewOption==4){
          return []
        } else {
          return []; // 处理未匹配到的情况
        }
      }
    },

    isWorkDay() {
      return (data,item)=>{
        const starttime=new Date(data.starttime)
        const endtime=new Date(data.endtime)
        const thisDay=new Date(item.date)
        if((thisDay>=starttime)&&(thisDay<=endtime)) {
          return true
        } else {
          return false
        }
      }
    },

    computedSelectResPlanSum(){
      return(resname,date)=>{

        let foundObject=null

        
        this.selectProjectsResPlansByDate.find((item)=>{
          // const aDate=new Date(item.date)
          
          if(item.date==date){
            foundObject=item
          }
        });

        
        const resplanArray=foundObject?foundObject.resplanArray:null
        const ResObject=resplanArray?resplanArray.find(item=>item.resname===resname):null
        if(ResObject){
          return ResObject.dayrespofreq
        }else {
          return 0
        }
        

      }
    },

    computedSelectResCostPlanSum(){
      return(resname,date)=>{

        let foundObject=null

        
        this.selectProjectsResPlansByDate.find((item)=>{
          // const aDate=new Date(item.date)
          
          if(item.date==date){
            foundObject=item
          }
        });

        
        const resplanArray=foundObject?foundObject.resplanArray:null
        const ResObject=resplanArray?resplanArray.find(item=>item.resname===resname):null
        if(ResObject){
          return ResObject.daytotalcost
        }else {
          return 0
        }
        

      }
    },


    computedDayWorkerNum(){
      return (date)=>{
        let resplanArray=[]
        this.selectProjectsResPlansByDate.find((item)=>{
          if(item.date==date){
            resplanArray=item.resplanArray
          }
        })
        let workerNum=0
        if(resplanArray.length>0){
          resplanArray.forEach(item=>{
            if(item.restype=='工人'){
              workerNum=workerNum+item.dayrespofreq
            }
          })
          return workerNum
        }else {
          return 0
        }
      }
    },

    computedDayCost(){
      return (date)=>{
        let resplanArray=[]
        this.selectProjectsResPlansByDate.find((item)=>{
          if(item.date==date){
            resplanArray=item.resplanArray
          }
        })
        let workerNum=0
        if(resplanArray.length>0){
          resplanArray.forEach(item=>{
            workerNum += Number(item.daytotalcost)
          })
          return workerNum
        }else {
          return 0
        }
      }
    },


    computedEquipmentNum(){
      return (date)=>{
        let resplanArray=[]
        this.selectProjectsResPlansByDate.find((item)=>{
          if(item.date==date){
            resplanArray=item.resplanArray
          }
        })
        // const resplanArray=foundObject.resplanArray
        let equipmentNum=0
        if(resplanArray.length>0){
          resplanArray.forEach(item=>{
            if(item.restype=='设备'){
              equipmentNum=equipmentNum+item.dayrespofreq
            }
          })
          return equipmentNum
        }else {
          return 0
        }
      }
    },

  },

  watch:{

    resResourceTtile: function(newVal, oldVal){
      if(newVal=='资源需求计划表'){
        this.showResNumOrMoneyNum=true
        if(this.resViewOption==0){
          this.resViewOption=1
        }
        
      } else if(newVal=='资源需求曲线图'){
        this.showResNumOrMoneyNum=true
        this.resViewOption=0
        // this.updateResChartDate()
        this.updateResChartDateOfCertainRes('全部','工人')
          .then((result)=>{
            this.resPlanChartTitle={text:'工人需求时间曲线图',left: 'center'}
            this.drawChart()
          })
      } else if(newVal=='资金需求计划表'){
        this.showResNumOrMoneyNum=false
        if(this.resViewOption==0){
          this.resViewOption=1
        }

      } else {
        this.showResNumOrMoneyNum=false
        this.resViewOption=0
        this.updateResChartDateOfCertainRes('全部','资金')
          .then((result)=>{
            this.resPlanChartTitle={text:'资金需求时间曲线图(RMB)',left: 'center'}
            this.drawChart()
          })
      }
    },


    // resResourceTtile: function(newVal, oldVal){
    //   if(newVal=='资源需求计划表'){
    //     this.showResNumOrMoneyNum=true
    //     if(this.resViewOption==0){
    //       this.resViewOption=1
    //     }
        
    //   } else {
    //     this.showResNumOrMoneyNum=true
    //     this.resViewOption=0
    //     this.updateResChartDate()
    //       .then((result)=>{
    //         this.resPlanChartTitle={text:'工人需求时间曲线图',left: 'center'}
    //         this.drawChart()
    //       })
    //   }
    // },



  },



  methods: {

    computedSelectResNameArrayx(){
        let resNameOption=[]
        let workerArray=[{value:'全部工人',label: '全部工人'}]
        let equipmentArray=[{value:'全部设备',label: '全部设备'}]
        let materialArray=[{value:'全部材料',label: '全部材料'}]
        let moneyArray=[{value:'资金',label: '全部资金'}]
        this.selectMergedResPlanArray.forEach((item)=>{
          if(item.restype=='工人'){
            workerArray.push({value:item.resname,label:item.resname})
          }else if(item.restype=='设备'){
            equipmentArray.push({value:item.resname,label:item.resname})
          }else if(item.restype=='材料'){
            materialArray.push({value:item.resname,label:item.resname})
          }else if(item.restype=='资金'){
            moneyArray.push({value:item.resname,label:item.resname})
          }
        })

        resNameOption.push({value:'工人',children:workerArray,label:'工人'})
        resNameOption.push({value:'设备',children:equipmentArray,label:'设备'})
        resNameOption.push({value:'材料',children:materialArray,label:'材料'})
        resNameOption.push({value:'资金',children:moneyArray,label:'资金'})
 
        return resNameOption
    },

    handleCascaderChange(value){
      // this.selectedValue = value;
      // console.log("选中的值：", this.selectedValue);
      // this.updateResChartDateOfCertainResName(value[0],value[1])
      //   .then((result)=>{
      //     console.log('this.chartSeriesArray',this.chartSeriesArray)
      //   })


      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null; // 可选的，将变量置为 null
      }

      this.updateResChartDateOfCertainRes(value[1],value[0])
        .then((result)=>{
          const resType=value[0]
          if(this.resResourceTtile=='资源需求计划表'||this.resResourceTtile=='资源需求曲线图'){
            this.resPlanChartTitle={text:`${resType}需求曲线图`,left: 'center'}
          } else {
            this.resPlanChartTitle={text:`${resType}费用需求曲线图(RMB)`,left: 'center'}
          }
          
          this.drawChart()
        })

      // if(value[0]=='工人'){
      //   this.updateResChartDateOfCertainWorker(value[1])
      //     .then((result)=>{ 
      //       this.resPlanChartTitle={text:'劳动力需求时间曲线图',left: 'center'}
      //       this.drawChart()
            
      //     })
      // } else if(value[0]=='设备'){
      //   this.updateResChartDateOfCertainEquip(value[1])
      //     .then((result)=>{
      //       this.resPlanChartTitle={text:'设备需求时间曲线图',left: 'center'}
      //       this.drawChart()
      //     })
      // } else if(value[0]=='材料'){
      //   this.updateResChartDateOfCertainMaterial(value[1])
      //     .then((result)=>{
      //       this.resPlanChartTitle={text:'材料需求时间曲线图',left: 'center'}
      //       this.drawChart()
      //     })

      // }
    },

    //根据AllResPlans计算所有资源需求计划的最早开始和最晚结束时间
    getResReqDuration(AllResPlans){
      

      let earliestStartDate = new Date(AllResPlans[0].respstarttime);
      let latestEndDate = new Date(AllResPlans[0].respendtime);
  
      // 找到最早开始时间和最晚结束时间
      AllResPlans.forEach(plan => {

        if (plan.respstarttime && plan.respendtime){
          const planStartDate = new Date(plan.respstarttime);
          const planEndDate = new Date(plan.respendtime);
          if (planStartDate < earliestStartDate) {
              earliestStartDate = planStartDate;
          }
          if (planEndDate > latestEndDate) {
              latestEndDate = planEndDate;
          }
        }
      });
  
      const startDate = new Date(earliestStartDate);
      const endDate = new Date(latestEndDate);
      return {startdate:startDate,enddate:endDate}

    },


    //这个函数很重要，感谢
    mergeResourcePlansByDay(AllResPlans, startday, endday) {
      const mergedResourcePlans = {};

      const startDate = new Date(startday);
      const endDate = new Date(endday);

      // 遍历每个资源计划对象
      AllResPlans.forEach(plan => {
        const planStartDate = new Date(plan.respstarttime);
        const planEndDate = new Date(plan.respendtime);
        const resname = plan.resname;
        const idfress_id = plan.idfress_id;
        const restype = plan.restype;
        const resunit=plan.resunit
        const resprice=plan.resprice

        // 确保资源计划对象的日期范围与给定的日期范围有重叠
        if (planStartDate <= endDate && planEndDate >= startDate) {
          // 遍历开始日期到结束日期的每一天
          for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            const dayKey = date.toLocaleDateString();
            const key = `${dayKey}`;
            if (!mergedResourcePlans[key]) {
              mergedResourcePlans[key] = {
                date: dayKey,
                resplanArray: []
              };
            }
            let existingResource = mergedResourcePlans[key].resplanArray.find(item => item.resname === resname);
            if (existingResource) {
              existingResource.dayrespofreq += (planStartDate <= date && planEndDate >= date) ? Number(plan.respofreq) : 0;
              existingResource.daytotalcost += (planStartDate <= date && planEndDate >= date) ? (resprice * Number(plan.respofreq)) : 0;
            } else {
              mergedResourcePlans[key].resplanArray.push({ idfress_id, resname, restype,resunit, 
                dayrespofreq: (planStartDate <= date && planEndDate >= date) ? Number(plan.respofreq) : 0, restype,
                daytotalcost: (planStartDate <= date && planEndDate >= date) ? (resprice * Number(plan.respofreq)).toFixed(0) : 0, });
            }
          }
        }
      });
    
      return Object.values(mergedResourcePlans);
    },

    cancelEditPro(){

      this.showEditTimeRuler=false
      this.switchMenuMask()
    },

    closeEditProj(startDay,endDay,interval){
      
      const resPlanStartDate = new Date(startDay);
      resPlanStartDate.setHours(0, 0, 0, 0);
      const resPlanEndDate = new Date(endDay);
      resPlanEndDate.setHours(0, 0, 0, 0);

      this.monthArray=this.getMonthDaysArrayWithInterval(resPlanStartDate,resPlanEndDate,interval)
      this.dayArray=this.getDaysArrayWithInterval(resPlanStartDate,resPlanEndDate,interval)
      this.showEditTimeRuler=false
      this.switchMenuMask()
    },

    timeRulerEditMenu(event){
      this.timeRulerEditMenuitems = [
        { ID:1,label: '时间刻度' }, 
        { ID:2,label: '开始时间' },
        { ID:3,label: '结束时间' },
      ]
      this.showTimeRulerEditMenu=true
      this.switchMenuMask()
      this.event={...event}  
      
      const top=(window.innerHeight-event.clientY)<189?(event.clientY-189):event.clientY
      const left=(window.innerWidth-event.clientX)<76?(event.clientX-76):event.clientX
      this.parentNode ={clientY:top+10,clientX:left+10}
      eventBus.$on('closeContexMenu', () => {
        this.showTimeRulerEditMenu=false
      })
      
    },

    timeRulereditMenuitemClicked(item) {
      //在这里处理右键菜单对应的选项
      this.showTimeRulerEditMenu=false
      //以下是项目编辑右键菜单的处理分支
      switch (item.ID){
        case 1: {
          // this.showProjEdit=true
          this.showEditTimeRuler=true
          break
        }
        case 2: {
          this.switchMenuMask()
          break
        }
        case 3: {
          this.switchMenuMask()
          break
        }
      }
    },

    inputDate(item) {
      // this.ifShowSelectedDateResPlans=true
      const selectedDate=item.date
      console.log('selectedDate',new Date(selectedDate))
      console.log('this.selectProjectsResPlansByDate',this.selectProjectsResPlansByDate)
      // const filterResPlansByDate=this.selectProjectsResPlansByDate.filter(item=>new Date(item.date)==new Date(selectedDate))
      this.selectProjectsResPlansByDate.find(item=>{
        if(new Date(item.date).getTime()==new Date(selectedDate).getTime()){
          this.selectedDayResPlan=item
          
        }
      })

      var materialResNames=[]
      var workCategorys=[]
      var equipmentNames=[]
      var summitRate=[]

      if(this.selectedDayResPlan.resplanArray.length>0){
        this.selectedDayResPlan.resplanArray.forEach(item=>{
          if(item.restype=='材料'){
            materialResNames.push(item.resname)
            
          }else if(item.restype=='设备'){
            equipmentNames.push(item.resname)
          }else if(item.restype=='工人'){
            workCategorys.push(item.resname)
            summitRate.push(item.dayrespofreq)
          }
        })
      }

      console.log('hongyue',workCategorys,summitRate)

      

      var option = {
        title : {
          text: '日资源需求情况',  
          subtext: '工人',  
          left: 'center'
        },
        xAxis: {
          type: 'category',
          data: workCategorys
        },
        yAxis: {
          min:0,
          max:100,
          axisLabel: {
            formatter: '{value}人'
          },
        },

        series: [
          {
            data: summitRate,
            type: 'bar',
            label: {  
              show: true, // 显示数据标签  
              position: 'top', // 标签位置在柱子上方  
              formatter: '{c}', // 格式化标签为百分比  
            },
          }

        ]
      };

      // this.drawChartOfDateResPlans(option)
    },



    mergeObjectsByProperty(arr, prop) {
    
      //要保留其他属性
      let resultMap = {};
      
       arr.forEach(item => {
         if (resultMap[item[prop]]) {
           resultMap[item[prop]].num += item.num;
           // 保留其他属性
           for (let key in item) {
             if (key !== prop && key !== 'num') {
               resultMap[item[prop]][key] = item[key];
             }
           }
         } else {
           resultMap[item[prop]] = { [prop]: item[prop], num: item.num, ...item }; // 保留其他属性
         }
       });
      
       let resultArray = Object.values(resultMap);
       return resultArray;
    },

    convertStringToResourceArray(task) {
      let str=task.resources
      if(!str==''){
        let items = str.split(";").filter(item => item.length > 0);
        let result = items.map(item => {
          let parts = item.split("-");
          let str1 = parts[0];
          let matchResult = str1.match(/^(\d+)(.*)$/);
          let numPart = matchResult[1];
          let otherPart = matchResult[2];
          let num = parseInt(numPart);
          // let unit = numUnit[1];
          let resname = parts[1];
          return {num:num, resunit:otherPart, resname:resname};
        });
        return result;
      } else {
        return []
      }
    },

    //不能单纯把所有任务的资源需求同类相加，而应该具体到某一天
    computedallTaskResourcesOfCertainDay(taskArray,date){
      let allTaskResArray=[]
      const thisDay=new Date(date)
      taskArray.forEach(item=>{
        const resArray=this.convertStringToResourceArray(item)
        
        const taskStarttime=new Date(item.starttime)
        const taskEndtime=new Date(item.endtime)
        if(resArray.length>0&&(thisDay>=taskStarttime)&&(thisDay<=taskEndtime)){
          allTaskResArray= allTaskResArray.concat(resArray)
        }
      })
      return this.mergeObjectsByProperty(allTaskResArray,'resname')
      // return allTaskResArray
    },

    getMonthDaysArrayWithInterval(startDate, endDate, interval) {
      let result = [];
      let currentDate = new Date(startDate);
      const end = new Date(endDate);
    
      while (currentDate <= end) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const daysInMonth = new Date(year, month, 0).getDate();
        let days = 0;
    
        if (currentDate.getMonth() === end.getMonth() && currentDate.getFullYear() === end.getFullYear()) {
          const diffTime = end.getTime() - currentDate.getTime();
          days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        } else {
          // days = daysInMonth - currentDate.getDate()+1;
          days = daysInMonth - currentDate.getDate()
        }
    
        // result.push({ month: `${year}年${month}月`, days: Math.ceil(days / interval) });
        result.push({ month: `${year}年${month}月`, days: Math.floor(days / interval)+1 });
    
        const remainder=days % interval
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(interval-remainder);
      }
    
      return result;
    },

    getMonthDaysArray(startDate, endDate) {
      let result = [];
      let currentDate = new Date(startDate);
      const end = new Date(endDate);
    
      while (currentDate <= end) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const daysInMonth = new Date(year, month, 0).getDate();
        let days = 0;
    
        if (currentDate.getMonth() === end.getMonth() && currentDate.getFullYear() === end.getFullYear()) {
          days = end.getDate() - currentDate.getDate() + 1;
        } else {
          days = daysInMonth - currentDate.getDate() + 1;
        }
    
        result.push({ month: `${year}年${month}月`, days: days });
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(1);
      }
    
      return result;
    },



    getDaysArray(startDate, endDate) {
      let result = [];
      let currentDate = new Date(startDate);
      const end = new Date(endDate);

    
      while (currentDate <= end) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const daysInMonth = new Date(year, month, 0).getDate();
    
        for (let day = 1; day <= daysInMonth; day++) {
          const date = new Date(year, month - 1, day);
          if (date <= end && date >= currentDate) {
            result.push({ date: `${year}/${month}/${day}`, day: day });
          }
        }
    
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(1); // 设置为下个月的第一天，以便在下一次循环中正确判断月份
      }
    
      return result;
    },


    getDaysArrayWithInterval(startDate, endDate, interval) {
      let result = [];
      let currentDate = new Date(startDate);
      const end = new Date(endDate);
      while (currentDate <= end) {
        const date = new Date(currentDate);
        const day = date.getDate();
        const formattedDate = `${date.getFullYear()}/${date.getMonth() + 1}/${day}`;
        const resourceArray=this.computedallTaskResourcesOfCertainDay(this.selectTasks,currentDate)
        result.push({ date: formattedDate, day: day ,resourcearray:resourceArray});
        currentDate.setDate(currentDate.getDate() + interval);
      }
      return result;
    },

    computedDayWorkerNum1(date){
        let resplanArray=[]
        this.selectProjectsResPlansByDate.find((item)=>{
          if(item.date==date){
            resplanArray=item.resplanArray
          }
        })
        // const resplanArray=foundObject.resplanArray
        let workerNum=0
        if(resplanArray.length>0){
          resplanArray.forEach(item=>{
            if(item.restype=='工人'){
              workerNum=workerNum+item.dayrespofreq
            }
          })
          return workerNum
        }else {
          return 0
        }
    },

    computedDayWorkerNum2(workerNums,date) {
      let resplanArray = this.selectProjectsResPlansByDate.find(item => item.date === date)?.resplanArray || [];
      // let workerNums = {}; // 存储每个工种的数量
      resplanArray.forEach(item => {
        if (item.restype === '工人') {
            if (item.resname in workerNums) {
                workerNums[item.resname].push(item.dayrespofreq)
            } else {
                // workerNums[item.resname] = item.dayrespofreq;
                workerNums[item.resname] = []
                workerNums[item.resname].push(item.dayrespofreq)
            }
        }
      });
      return workerNums
    },

    computedDayCertainWorkerNum(workerNums,date,resname) {
      let resplanArray = this.selectProjectsResPlansByDate.find(item => item.date === date)?.resplanArray || [];
      // let workerNums = {}; // 存储每个工种的数量
      resplanArray.forEach(item => {
        if(resname=='全部'){
          if (item.restype === '工人') {
            if (item.resname in workerNums) {
                workerNums[item.resname].push(item.dayrespofreq)
            } else {
                // workerNums[item.resname] = item.dayrespofreq;
                workerNums[item.resname] = []
                workerNums[item.resname].push(item.dayrespofreq)
            }
          }

        }else {
          if (item.restype === '工人'&&item.resname==resname) {
            if (item.resname in workerNums) {
                workerNums[item.resname].push(item.dayrespofreq)
            } else {
                // workerNums[item.resname] = item.dayrespofreq;
                workerNums[item.resname] = []
                workerNums[item.resname].push(item.dayrespofreq)
            }
          }
        }
      });
      return workerNums
    },

    computedDayCertainEquipNum(equipNums,date,resname) {
      let resplanArray = this.selectProjectsResPlansByDate.find(item => item.date === date)?.resplanArray || [];
      // let workerNums = {}; // 存储每个工种的数量
      resplanArray.forEach(item => {
        if(resname=='全部'){
          if (item.restype === '设备') {
            if (item.resname in equipNums) {
              equipNums[item.resname].push(item.dayrespofreq)
            } else {
                // workerNums[item.resname] = item.dayrespofreq;
                equipNums[item.resname] = []
                equipNums[item.resname].push(item.dayrespofreq)
            }
          }

        }else {
          if (item.restype === '设备'&&item.resname==resname) {
            if (item.resname in equipNums) {
              equipNums[item.resname].push(item.dayrespofreq)
            } else {
                // workerNums[item.resname] = item.dayrespofreq;
                equipNums[item.resname] = []
                equipNums[item.resname].push(item.dayrespofreq)
            }
          }
        }
      });
      return equipNums
    },

    computedDayCertainMaterialNum(materialNums,date,resname) {
      let resplanArray = this.selectProjectsResPlansByDate.find(item => item.date === date)?.resplanArray || [];
      // let workerNums = {}; // 存储每个工种的数量
      resplanArray.forEach(item => {
        if(resname=='全部'){
          if (item.restype === '材料') {
            if (item.resname in materialNums) {
              materialNums[item.resname].push(item.dayrespofreq)
            } else {
                // workerNums[item.resname] = item.dayrespofreq;
                materialNums[item.resname] = []
                materialNums[item.resname].push(item.dayrespofreq)
            }
          }

        }else {
          if (item.restype === '材料'&&item.resname==resname) {
            if (item.resname in materialNums) {
              materialNums[item.resname].push(item.dayrespofreq)
            } else {
                // workerNums[item.resname] = item.dayrespofreq;
                materialNums[item.resname] = []
                materialNums[item.resname].push(item.dayrespofreq)
            }
          }
        }
      });
      return materialNums
    },


    computedDayCertainResourceNum(resourceNums, date, resname, restype) {
      let resplanArray = this.selectProjectsResPlansByDate.find(item => item.date === date)?.resplanArray || [];
      resplanArray.forEach(item => {
        if ((resname === '全部'&&item.restype === restype) || (item.restype === restype && item.resname === resname)) {
          if (item.resname in resourceNums) {
            resourceNums[item.resname].push(item.dayrespofreq);
          } else {
            resourceNums[item.resname] = [item.dayrespofreq];
          } 
        } 
      });
    
      return resourceNums;
    },

    // computedDayCertainResourceCost(resourceCost, date, resname, restype) {
    //   let resplanArray = this.selectProjectsResPlansByDate.find(item => item.date === date)?.resplanArray || [];
    //   console.log('resplanArray',resplanArray)
    //   resplanArray.forEach(item => {
    //     if ((resname === '全部'&&item.restype === restype) || (item.restype === restype && item.resname === resname)) {
    //       if (item.resname in resourceCost) {
    //         resourceCost[item.resname].push(item.daytotalcost);
    //       } else {
    //         resourceCost[item.resname] = [item.daytotalcost];
    //       } 
    //     } 
    //   });
    //   return resourceCost;
    // },

    methodDayAllCost(date,restype){
      let resplanArray=[]
      this.selectProjectsResPlansByDate.find((item)=>{
        if(item.date==date){
          resplanArray=item.resplanArray
        }
      })

      let newResplanArray=[]
      if(restype=='资金'){
        newResplanArray=resplanArray
      } else {
        newResplanArray=resplanArray.filter(item=>item.restype==restype)
      }
      let workerNum=0
      if(newResplanArray.length>0){
        newResplanArray.forEach(item=>{
          workerNum += Number(item.daytotalcost)
        })
        return workerNum
      }else {
        return 0
      }
    },

    // computedDayCertainResourceCost(resourceCost, date, resname, restype) {
    //   let resplanArray = this.selectProjectsResPlansByDate.find(item => item.date === date)?.resplanArray || [];
    //   let allCost=0
    //   resplanArray.forEach(item => {
    //     if (restype === '资金'&&resname=='全部') {
    //       resourceCost[restype]=[]
    //       this.dayArray.forEach((item)=>{
    //         const dayCost=this.methodDayAllCost(item.date)
    //         resourceCost[restype].push(dayCost)
    //       })
  
    //     } else if((item.restype === restype && item.resname === resname)){
    //       if (item.resname in resourceCost) {
    //         resourceCost[item.resname].push(item.daytotalcost);
    //       } else {
    //         resourceCost[item.resname] = [item.daytotalcost];
    //       } 
    //     }
    //   });
    //   return resourceCost;
    // },

    computedDayCertainResourceCost(resourceCost, date, resname, restype) {
      let resplanArray = this.selectProjectsResPlansByDate.find(item => item.date === date)?.resplanArray || [];
      let allCost=0
      resplanArray.forEach(item => {
        if (restype === '资金'&&resname=='全部') {
          resourceCost[restype]=[]
          this.dayArray.forEach((item)=>{
            const dayCost=this.methodDayAllCost(item.date,'资金')
            resourceCost[restype].push(dayCost)
          })
  
        } else if((item.restype === restype && resname=='全部')){

          resourceCost[restype]=[]
          this.dayArray.forEach((item)=>{
            const dayCost=this.methodDayAllCost(item.date,restype)
            resourceCost[restype].push(dayCost)
          })

        } else if((item.restype === restype && item.resname === resname)){
          if (item.resname in resourceCost) {
            resourceCost[item.resname].push(item.daytotalcost);
          } else {
            resourceCost[item.resname] = [item.daytotalcost];
          } 
        }
      });
      return resourceCost;
    },


    // computedDayCertainResNum(certainResNums,date,restype,resname) {
    //   // let certainResNums={}
    //   let resplanArray = this.selectProjectsResPlansByDate.find(item => item.date === date)?.resplanArray || [];
    //   // let workerNums = {}; // 存储每个工种的数量
    //   resplanArray.forEach(item => {
    //     if (item.restype === restype&&resname=='全部') {
    //         if (item.resname in certainResNums) {
    //           certainResNums[item.resname].push(item.dayrespofreq)
    //         } else {
    //             // workerNums[item.resname] = item.dayrespofreq;
    //             certainResNums[item.resname] = []
    //             certainResNums[item.resname].push(item.dayrespofreq)
    //         }
    //     } else if(item.restype === restype&&resname==item.resname){
    //       if (item.resname in certainResNums) {
    //           certainResNums[item.resname].push(item.dayrespofreq)
    //         } else {
    //             // workerNums[item.resname] = item.dayrespofreq;
    //             certainResNums[item.resname] = []
    //             certainResNums[item.resname].push(item.dayrespofreq)
    //         }
    //     } else {
    //       certainResNums={}
    //     }
    //   });
    //   return certainResNums
    // },

    computedDayWorkerNum3(date) {
      let workerNums={}
      let workerNames=[]
      let resplanArray = this.selectProjectsResPlansByDate.find(item => item.date === date)?.resplanArray || [];
      resplanArray.forEach(item => {
        if (item.restype === '工人') {
            if (item.resname in workerNums) {
                workerNums[item.resname].push(item.dayrespofreq)
            } else {
                // workerNums[item.resname] = item.dayrespofreq;
                workerNums[item.resname] = []
                workerNums[item.resname].push(item.dayrespofreq)
                workerNames.push(item.resname)
            }
        }
      });
      return {workerNums:workerNums,workerNames:workerNames}  //workerNums是一个数组，workerNums是一个对象
    },

    //以下是echarts相关的函数
    // drawChart1() {
    //   //2. 基于准备好的dom，初始化echarts实例
    //   var myChart = this.$echarts.init(document.getElementById('main'));
    //   myChart.on('click', function (params) {
    //       // 控制台打印数据的名称
    //   });
    //   //3. 指定图表的配置项和数据
    //   let dateArray=[]
    //   let workerNums = {}
    //   this.dayArray.forEach(item=>{
    //     dateArray.push(item.date)
    //     this.computedDayWorkerNum2(workerNums,item.date)
        
    //   })


      
    //   const firstValue1=Object.values(workerNums)[0]
    //   const firstValue2=Object.values(workerNums)[1]
    //   const firstValue3=Object.values(workerNums)[2]
    //   console.log('firstValue1',firstValue1)
       
    //   var option = {
    //     title: {
    //         text: '劳动力需求时间曲线图',
    //         left: 'center',
    //     },
    //     xAxis: {
    //       type: 'category',
    //       data: dateArray,

    //     },
    //     yAxis: {
    //       type: 'value',

    //     },
    //     legend: {
    //       data: ['钢筋工','管理工','泥水工'], // 图例显示的名称
    //       top: 50, // 调整图例位置
    //     },
    //     series: [
    //       {
    //         name:'钢筋工',
    //         data: firstValue1,
    //         type: 'line',
    //         smooth: true,
    //         label: {
    //           show: true,
    //           position: 'top'
    //         }

    //       },
    //       {
    //         name:'管理工',
    //         data: firstValue2,
    //         type: 'line',
    //         smooth: true,
    //         label: {
    //           show: true,
    //           position: 'top'
    //         },
    //       },
    //       {
    //         name:'泥水工',
    //         data: firstValue3,
    //         type: 'line',
    //         smooth: true,
    //         label: {
    //           show: true,
    //           position: 'top'
    //         },
    //       }
    //     ]
    //   };
    //   //4.使用刚指定的配置项和数据显示图表。
    //   myChart.setOption(option);
    // },

    //以下是更新资源需求曲线图数据的函数
    // updateResChartDate() {
    //   this.chartDayArray=[]
    //   let dateArray=[]
    //   let workerNums = {}
    //   this.dayArray.forEach(item=>{
    //     this.chartDayArray.push(item.date)
    //     // const dateWorkerNum=this.computedDayWorkerNum1(item.date)
    //     this.computedDayWorkerNum2(workerNums,item.date)
    //   })
    //   console.log('workerNums',workerNums)

    //   // 使用Object.keys()方法获取对象的所有属性名称数组
    //   const keys = Object.keys(workerNums);
    //   this.chartKeys=keys
      
    //   // 获取属性数量
    //   const numProperties = keys.length;
    //   // let arrayEX=[]
    //   this.chartSeriesArray=[]
    //   for(var i=0;i<numProperties;i++) {
    //     var dataArray=workerNums[keys[i]]
    //     const aObject={
    //       name:keys[i],
    //       data:[...dataArray],
    //       type:'line',
    //       smooth:true,
    //       label:{
    //         show:true,
    //         position:'top'
    //       }
    //     }
    //     this.chartSeriesArray.push(aObject)
    //   }
    
    // },

    async updateResChartDate() {
      this.chartDayArray = [];
      let dateArray = [];
      let workerNums = {};
  
      // 使用 Promise 包装异步操作
      await new Promise((resolve, reject) => {
          this.dayArray.forEach(item => {
              this.chartDayArray.push(item.date);
              this.computedDayWorkerNum2(workerNums, item.date);
          });
          resolve();
      });
  
  
      const keys = Object.keys(workerNums);
      this.chartKeys = keys;
  
      const numProperties = keys.length;
      this.chartSeriesArray = [];
  
      for (let i = 0; i < numProperties; i++) {
        const dataArray = workerNums[keys[i]];
        const aObject = {
          name: keys[i],
          data: [...dataArray],
          type: 'line',
          smooth: true,
          label: {
              show: true,
              position: 'top'
          }
        };
        this.chartSeriesArray.push(aObject);
      }
    },

    async updateResChartDateOfCertainWorker(resname) {
      this.chartDayArray = [];
      let dateArray = [];
      let workerNums = {};
      // let certainResNums={}
  
      // 使用 Promise 包装异步操作
      await new Promise((resolve, reject) => {
          this.dayArray.forEach(item => {
              this.chartDayArray.push(item.date);

              this.computedDayCertainWorkerNum(workerNums,item.date,resname)

          });
          resolve();
      });
  
  
      const keys = Object.keys(workerNums);
      this.chartKeys = keys;
  
      const numProperties = keys.length;
      this.chartSeriesArray = [];
  
      for (let i = 0; i < numProperties; i++) {
        const dataArray = workerNums[keys[i]];
        const aObject = {
          name: keys[i],
          data: [...dataArray],
          type: 'line',
          smooth: true,
          label: {
              show: true,
              position: 'top'
          }
        };
        this.chartSeriesArray.push(aObject);
      }
    },


    async updateResChartDateOfCertainEquip(resname) {
      this.chartDayArray = [];
      let dateArray = [];
      let equipNums = {};
      // let certainResNums={}
  
      // 使用 Promise 包装异步操作
      await new Promise((resolve, reject) => {
          this.dayArray.forEach(item => {
              this.chartDayArray.push(item.date);

              this.computedDayCertainEquipNum(equipNums,item.date,resname)


          });
          resolve();
      });
  
  
      const keys = Object.keys(equipNums);
      this.chartKeys = keys;
  
      const numProperties = keys.length;
      this.chartSeriesArray = [];
  
      for (let i = 0; i < numProperties; i++) {
        const dataArray = equipNums[keys[i]];
        const aObject = {
          name: keys[i],
          data: [...dataArray],
          type: 'line',
          smooth: true,
          label: {
              show: true,
              position: 'top'
          }
        };
        this.chartSeriesArray.push(aObject);
      }
    },

    async updateResChartDateOfCertainMaterial(resname){
      this.chartDayArray = [];
      let dateArray = [];
      let materialNums = {};
      // let certainResNums={}
  
      // 使用 Promise 包装异步操作
      await new Promise((resolve, reject) => {
          this.dayArray.forEach(item => {
              this.chartDayArray.push(item.date);

              this.computedDayCertainMaterialNum(materialNums,item.date,resname)


          });
          resolve();
      });
  
  
      const keys = Object.keys(materialNums);
      this.chartKeys = keys;
  
      const numProperties = keys.length;
      this.chartSeriesArray = [];
  
      for (let i = 0; i < numProperties; i++) {
        const dataArray = materialNums[keys[i]];
        const aObject = {
          name: keys[i],
          data: [...dataArray],
          type: 'line',
          smooth: true,
          label: {
              show: true,
              position: 'top'
          }
        };
        this.chartSeriesArray.push(aObject);
      }

    },


    async updateResChartDateOfCertainRes(resname,restype){
      this.chartDayArray = [];
      let dateArray = [];
      let resNums = {};

  
      // 使用 Promise 包装异步操作
      await new Promise((resolve, reject) => {
          this.dayArray.forEach(item => {
              this.chartDayArray.push(item.date);

              if(this.resResourceTtile=='资源需求曲线图'){
                this.computedDayCertainResourceNum(resNums,item.date,resname,restype)
              }else {
                this.computedDayCertainResourceCost(resNums,item.date,resname,restype)
              }
          });
          resolve();
      });
  
  
      const keys = Object.keys(resNums);
      this.chartKeys = keys;
  
      const numProperties = keys.length;
      this.chartSeriesArray = [];
  
      for (let i = 0; i < numProperties; i++) {
        const dataArray = resNums[keys[i]];
        const aObject = {
          name: keys[i],
          data: [...dataArray],
          type: 'line',
          smooth: true,
          label: {
              show: true,
              position: 'top'
          }
        };
        this.chartSeriesArray.push(aObject);
      }

    },

    //以下是echarts相关的函数
    drawChart() {
      var option = {
        // title: {
        //     text: '劳动力需求时间曲线图',
        //     left: 'center',
        // },
        title: this.resPlanChartTitle,
        xAxis: {
          type: 'category',
          // data: dateArray,
          data: this.chartDayArray,

        },
        yAxis: {
          type: 'value',

        },
        legend: {
          orient: 'horizontal',
          data:this.chartKeys,
          top: 'bottom', // 调整图例位置
        },
        // series:[...arrayEX]
        // series:this.chartSeriesArray

        series: this.chartSeriesArray.map((seriesItem, index) => {
            return {
                ...seriesItem,
                symbolSize: 8, // 设置数据点大小
                data: seriesItem.data.map((dataItem, dataIndex) => {
                    return {
                        value: dataItem,
                        symbol: 'circle', // 设置数据点形状为圆形
                        symbolSize: 8, // 设置数据点大小
                        itemStyle: {
                            color: index * 5 + dataIndex, // 根据索引设置颜色，避免重叠
                        },
                        label: {
                            show: true,
                            offset: [0, -10], // 设置标签向上偏移10px
                            position: 'top', // 设置标签显示在曲线上方
                            rotate: 45, // 设置标签竖向显示
                            color: 'grey', // 设置标签颜色为黑色
                        }
                    };
                })
            };
        })
      };
      // var myChart = this.$echarts.init(document.getElementById('main'));
      this.myChart = this.$echarts.init(document.getElementById('main'));
      this.myChart.on('click', function (params) {
          // 控制台打印数据的名称
      });
      //4.使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
    },


    drawChartOfDateResPlans(option) {
      // 检查 myChart 是否已经存在，如果存在则销毁它  
    // if (this.selectedDateResPlansChart && typeof this.selectedDateResPlansChart.dispose === 'function') {  
    //     this.selectedDateResPlansChart.dispose(); // 销毁旧的图表实例  
    //     // this.myChart=null
    //   }  

      const main1=document.getElementById('main1')
      console.log('main1',main1)

      this.selectedDateResPlansChart = this.$echarts.init(document.getElementById('main1'));
      console.log('hongyue',this.selectedDateResPlansChart)
      this.selectedDateResPlansChart.on('click', function (params) {
          // 控制台打印数据的名称
      });
      //4.使用刚指定的配置项和数据显示图表。
      this.selectedDateResPlansChart.setOption(option);
    },

    

    switchResourceView(n){
      this.resViewOption=n
      this.resResourceTtile='资源需求计划表'

      if(this.resViewOption==2){
        // this.resResourceTtile='资源需求曲线图'
        // this.updateResChartDate()
        //   .then((result)=>{
        //     this.drawChart()
        //     console.log('this.chartSeriesArray',this.chartSeriesArray)
        //   })
      }

      if(this.resViewOption==1){
        this.resResourceTtile='资源需求计划表'
        // this.updateResChartDate()
        //   .then((result)=>{
        //     this.drawChart()
        //     console.log('this.chartSeriesArray',this.chartSeriesArray)
        //   })
        
      }


      
    },


    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
    }),

    ...mapActions({
      getMyTaskAclList:'getMyTaskAclList',
      getTaskinfoByIdft: 'getTaskinfoByIdft',
      getMyProjectListByIdfp:'getMyProjectListByIdfp',
      convertUTCtoCST:'convertUTCtoCST',
      getTaskContents:'getTaskContents',
      getTaskAclsByTaskIdft:'getTaskAclsByTaskIdft',
      getProjectAclListByIdfp:'getProjectAclListByIdfp',
      getUserInfoByUsername:'getUserInfoByUsername',
      getMyProjectAclList:'getMyProjectAclList',
      getTasksByIdfp:'getTasksByIdfp',
      // getResPlanlistByTask:'getResPlanlistByTask'
    }),

    //根据文件idff获取相应的文件程序信息记录
    async getResPlanlistByTask(task) {
      try {
        const token = localStorage.getItem('token');
        const data = { resp_idft: task.idft };
        const response = await axios.get('https://api2.itaskid.com' + '/my/getresplanlistbytask', {
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        if (Array.isArray(response.data)) {
          return response.data;
        } else {
          alert('账号未认证，无法获取数据！');
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
        return []
      }
    },



    //根据选择不同的项目，在日历呈现不同的任务
    selectAllProject(){
      this.selectTasks=this.tasklistall
      this.selectProjectResPlans=this.myAllTaskResPlans
      if(this.selectProjectResPlans.length>0){
        this.allResReqDuration=this.getResReqDuration(this.selectProjectResPlans)
        const selectProjectsResPlansByDate=this.mergeResourcePlansByDay(this.selectProjectResPlans,this.allResReqDuration.startdate,this.allResReqDuration.enddate)
        this.selectProjectResReqDuration=this.allResReqDuration
        this.selectProjectsResPlansByDate=selectProjectsResPlansByDate
        this.selectMergedResPlanArray=this.selectProjectsResPlansByDate[0].resplanArray
        const resPlanStartDate = new Date(this.selectProjectResReqDuration.startdate);
        resPlanStartDate.setHours(0, 0, 0, 0);
        const resPlanEndDate = new Date(this.selectProjectResReqDuration.enddate);
        resPlanEndDate.setHours(0, 0, 0, 0);
        this.monthArray=this.getMonthDaysArrayWithInterval(resPlanStartDate,resPlanEndDate,1)
        this.dayArray=this.getDaysArrayWithInterval(resPlanStartDate,resPlanEndDate,1)
      }else {
        this.monthArray=[]
        this.dayArray=[]
        this.selectMergedResPlanArray=[]
        alert('此项目尚未设置资源计划！')
      }
      if(this.resViewOption==2){
        this.updateResChartDate()
          .then((result)=>{
            this.drawChart()
          })
      }
    },

    selectProjectTasks(idfp){
      this.selectTasks=this.tasklistall.filter(task=>task.project==idfp)
      

      this.selectProjectResPlans=this.myAllTaskResPlans.filter(resplan=>resplan.resp_idfp==idfp)
      console.log('this.selectProjectResPlans',this.selectProjectResPlans)
      if(this.selectProjectResPlans.length>0){
        this.allResReqDuration=this.getResReqDuration(this.selectProjectResPlans)

        const selectProjectsResPlansByDate=this.mergeResourcePlansByDay(this.selectProjectResPlans,this.allResReqDuration.startdate,this.allResReqDuration.enddate)
  
        this.selectProjectResReqDuration=this.allResReqDuration
        this.selectProjectsResPlansByDate=selectProjectsResPlansByDate

        console.log('this.selectProjectsResPlansByDate',this.selectProjectsResPlansByDate)
        this.selectMergedResPlanArray=this.selectProjectsResPlansByDate[0].resplanArray
        
        const resPlanStartDate = new Date(this.selectProjectResReqDuration.startdate);
        resPlanStartDate.setHours(0, 0, 0, 0);
        const resPlanEndDate = new Date(this.selectProjectResReqDuration.enddate);
        resPlanEndDate.setHours(0, 0, 0, 0);
        this.monthArray=this.getMonthDaysArrayWithInterval(resPlanStartDate,resPlanEndDate,1)
        this.dayArray=this.getDaysArrayWithInterval(resPlanStartDate,resPlanEndDate,1)
      }else {
        this.monthArray=[]
        this.dayArray=[]
        this.selectMergedResPlanArray=[]
        alert('此项目尚未设置资源计划！')
      }
      if(this.resViewOption==2){
        this.updateResChartDate()
          .then((result)=>{

            this.drawChart()
          })
      }
    },

    selectGroupTasks(group,selectProjects){
      this.selectTasks=this.tasklistall.filter(task=>task.group==group)
      const idfpArray = selectProjects.map(project => project.idfp);
      this.selectProjectResPlans=this.myAllTaskResPlans.filter(resplan=>idfpArray.includes(resplan.resp_idfp))
      if(this.selectProjectResPlans.length>0){
        this.allResReqDuration=this.getResReqDuration(this.selectProjectResPlans)
        const selectProjectsResPlansByDate=this.mergeResourcePlansByDay(this.selectProjectResPlans,this.allResReqDuration.startdate,this.allResReqDuration.enddate)
        this.selectProjectResReqDuration=this.allResReqDuration
        this.selectProjectsResPlansByDate=selectProjectsResPlansByDate
        this.selectMergedResPlanArray=this.selectProjectsResPlansByDate[0].resplanArray
        const resPlanStartDate = new Date(this.selectProjectResReqDuration.startdate);
        resPlanStartDate.setHours(0, 0, 0, 0);
        const resPlanEndDate = new Date(this.selectProjectResReqDuration.enddate);
        resPlanEndDate.setHours(0, 0, 0, 0);
        this.monthArray=this.getMonthDaysArrayWithInterval(resPlanStartDate,resPlanEndDate,1)
        this.dayArray=this.getDaysArrayWithInterval(resPlanStartDate,resPlanEndDate,1)
      }else {
        this.monthArray=[]
        this.dayArray=[]
        this.selectMergedResPlanArray=[]
        alert('此项目尚未设置资源计划！')
      }
      if(this.resViewOption==2){
   
        this.updateResChartDate()
          .then((result)=>{

            this.drawChart()
          })
      }
    },

    selectNoGroupTasks(proName){
      this.selectTasks=this.tasklistall.filter(task=>task.project==proName)
      this.selectProjectResPlans=this.myAllTaskResPlans.filter(resplan=>resplan.resp_idfp==proName)
      if(this.selectProjectResPlans.length>0){
        this.allResReqDuration=this.getResReqDuration(this.selectProjectResPlans)
        const selectProjectsResPlansByDate=this.mergeResourcePlansByDay(this.selectProjectResPlans,this.allResReqDuration.startdate,this.allResReqDuration.enddate)
        this.selectProjectResReqDuration=this.allResReqDuration
        this.selectProjectsResPlansByDate=selectProjectsResPlansByDate
        this.selectMergedResPlanArray=this.selectProjectsResPlansByDate[0].resplanArray
        const resPlanStartDate = new Date(this.selectProjectResReqDuration.startdate);
        resPlanStartDate.setHours(0, 0, 0, 0);
        const resPlanEndDate = new Date(this.selectProjectResReqDuration.enddate);
        resPlanEndDate.setHours(0, 0, 0, 0);
        this.monthArray=this.getMonthDaysArrayWithInterval(resPlanStartDate,resPlanEndDate,1)
        this.dayArray=this.getDaysArrayWithInterval(resPlanStartDate,resPlanEndDate,1)
      }else {
        this.monthArray=[]
        this.dayArray=[]
        this.selectMergedResPlanArray=[]
        alert('此项目尚未设置资源计划！')
      }
      if(this.resViewOption==2){
        this.updateResChartDate()
          .then((result)=>{
            this.drawChart()
          })
      }
    },
  

    convertDate(targetDate) {
      if (targetDate == '') {
        targetDate = '2999/12/31 23:59:59';  // 设置默认时间为23:59:59
      }
      const date = new Date(targetDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
    
      const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },


    //以下是任务数据库操作相关的方法
    async getMyProjects() {
      const token = localStorage.getItem('token');
      const username = localStorage.getItem('username');
    
      try {
        const response = await axios.get('https://api2.itaskid.com' + '/my/taskgroup', {
          headers: {
            Authorization: token
          },
        });
    
        const usernogroup = `${username}nogroup`;
        if (Array.isArray(response.data)) {
          this.itemsAll = response.data;
          this.myProjectList = response.data.filter(item => item.charger == username);
          this.items = this.myProjectList.filter(item => (!(item.idfp == usernogroup)));
        } else {
          alert('账号未认证，无法获取数据！');
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getAllTasks() {
      const token = localStorage.getItem('token');
      const username = localStorage.getItem('username');
      const user = { charger: username };
      try {
        const response = await axios({
          method: 'get',
          url: 'https://api2.itaskid.com' + '/my/tasklist',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: user
        });
        if (Array.isArray(response.data)) {
          console.log('获取任务列表成功！');
          return response.data
          
        } else {
          alert('账号未认证，无法获取数据！');
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },


    async fetchTasks() {
      const username = localStorage.getItem('username');
      const nogroup = `${username}nogroup`;
      let myTaskAcllist = [];
      let myChargeProjectTasks = [];
    
      try {
        // 获取通过项目关系获取的任务
        let projectAcllist = await this.getMyProjectAclList(username);

        //以下代码筛选了我负责的项目，即只有我负责的项目才能看到资源分布，其实不应该是这样,增加一个我观察的项目也可以看到资源投入
        projectAcllist = projectAcllist.filter(item => item.permit_level === 'charger'||item.permit_level === 'viewer');
    
        if (projectAcllist.length > 0) {
          const projectPromises = projectAcllist.map(async (projectacl) => {
            const tasks = await this.getTasksByIdfp(projectacl.project_idfp);
    
            return tasks;
          });
    
          const projectTasks = await Promise.all(projectPromises);
          myChargeProjectTasks = projectTasks.flat();
        }
    
        // 获取通过任务关系获取的任务，这样就可以看到我负责的项目需要多少资源了？？？
        myTaskAcllist = await this.getMyTaskAclList(username);
    
        if (myTaskAcllist.length > 0) {
          const taskPromises = myTaskAcllist.map(async (taskacl) => {
            const task = await this.getTaskinfoByIdft(taskacl.task_idft);
   
            return {
              ...taskacl,
              ...task[0]
            };
          });
    
          myTaskAcllist = await Promise.all(taskPromises);
        }
    
        // 合并两种方式获取的任务
        const allTasks = [...myChargeProjectTasks, ...myTaskAcllist];
    
        // 去重，确保相同的任务只保留一条
        const uniqueTasks = allTasks.reduce((acc, current) => {
          const x = acc.find(item => item.idft === current.idft);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
    
        // 处理任务的其他逻辑
        // this.myChargeProjectTasks = uniqueTasks;
        // const allXTasks=this.myChargeProjectTasks
        const allXTasks=uniqueTasks.filter(task=>task.done==0)
        allXTasks.sort((a, b) => new Date(a.starttime) - new Date(b.starttime));
        const projectPromises = allXTasks.map(async (task) => {

          const project = await this.getProjectinfoByIdfp(task.project);
          const resplans=await this.getResPlanlistByTask(task)
          task.resourceplans=resplans
          


          if(project.length>0){
            task.group = project[0].groupname;
            task.permit_level='charger'
          }else {
            const index=allXTasks.findIndex((t)=>t.idft===task.idft)
            if(index>-1){
              allXTasks.splice(index,1)
            }
          }

        });
    
        await Promise.all(projectPromises);
    
        // this.myChargeProjectTasks = allXTasks;

        // this.selectTasks = allXTasks;
        // allXTasks.forEach((task)=>{
        //   const resplans=await this.getResPlanlistByTask(task)
        // })

        this.myAllTaskResPlans=this.getAllResPlans(allXTasks)
        console.log('this.myAllTaskResPlans',this.myAllTaskResPlans)
        this.selectProjectResPlans=this.myAllTaskResPlans
        this.tasklistall=allXTasks
        this.selectTasks=allXTasks
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    },


    async getTasks() {
      const username = localStorage.getItem('username');
      const nogroup = `${username}nogroup`;
      let myTaskAcllist = [];
    
      try {
        myTaskAcllist = await this.getMyTaskAclList(username);
    
        if (myTaskAcllist.length > 0) {
          const promises = myTaskAcllist.map(async (taskacl) => {
            const task = await this.getTaskinfoByIdft(taskacl.task_idft);
            taskacl.name = task[0].name;
            taskacl.duration = task[0].duration;
            taskacl.endtime = task[0].endtime;
            taskacl.pretask = task[0].pretask;
            taskacl.level = task[0].level;
            taskacl.parent = task[0].parent;
            taskacl.project = task[0].project;
            taskacl.haschild = task[0].haschild;
            taskacl.done = task[0].done;
            taskacl.expanded = task[0].expanded;
            taskacl.id = task[0].id;
            taskacl.delete = task[0].delete;
            taskacl.starttime = task[0].starttime;
            taskacl.record = task[0].record
            taskacl.resourceplans=task[0].resourceplans
            
          });
    
          await Promise.all(promises);
        }
    
        if (Array.isArray(myTaskAcllist)) {
          // this.tasklistall = myTaskAcllist;
          this.myTaskAcllist=myTaskAcllist
          return myTaskAcllist
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },

    //根据idfp获取项目信息
    async getProjectinfoByIdfp(idfp) {
      const token = localStorage.getItem('token');
      const data={idfp: idfp}
      const username = localStorage.getItem('username');
    
      try {
        const response = await axios.get('https://api2.itaskid.com' + '/my/getprojectinfobyidfp', {
          headers: {
            Authorization: token
          },
          params:data
        });
        if (Array.isArray(response.data)) {
          return response.data
        } else {
          alert('账号未认证，无法获取数据！');
        }
      } catch (error) {
        console.log(error);
      }
    },  
    
    //国际标准时间转化成中国标准时间
    convertToChinaTime(dateTime) {
      const dateObj = new Date(dateTime);
      const chinaTime = new Date(dateObj.getTime() + (dateObj.getTimezoneOffset() * 60000) + (8 * 3600000));
      const hours = chinaTime.getHours().toString().padStart(2, '0');
      const minutes = chinaTime.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },

    //把标准时间转换成普通的字符串时间
    convertDateYMD(targetDate){
      const date = new Date(targetDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}/${month}/${day}`;
      return formattedDate
    },

    getAllResPlans(tasklist){
      let resourcePlans=[]
      if(tasklist){
        tasklist.forEach(task => {
        if (task.resourceplans && task.resourceplans.length > 0){
          task.resourceplans.forEach(resourceplan => {
            resourceplan.resp_idfp = task.project;
            
            //以下是根据资源的投入类型，根据task的最新开始和结束时间进行重新的修订

            var taskStarttime = new Date(task.starttime);
            var taskEndtime = new Date(task.endtime);

      
            if(resourceplan.resinputtype==4){
              resourceplan.respstarttime=this.convertDateYMD(resourceplan.respstarttime)
              resourceplan.respendtime=this.convertDateYMD(resourceplan.respendtime)
            }else if(resourceplan.resinputtype==1) {
              taskStarttime.setDate(taskStarttime.getDate() - resourceplan.resprepareday);
              const taskStartYear = taskStarttime.getFullYear();
              const taskStartMonth = taskStarttime.getMonth() + 1;
              const taskStartDay = taskStarttime.getDate();
              const taskStartDate = `${taskStartYear}/${taskStartMonth}/${taskStartDay}`;
      
              taskEndtime.setDate(taskEndtime.getDate() - resourceplan.resprepareday);
              const taskEndYear = taskStarttime.getFullYear();
              const taskEndMonth = taskStarttime.getMonth() + 1;
              const taskEndDay = taskStarttime.getDate();
              const taskEndDate = `${taskEndYear}/${taskEndMonth}/${taskEndDay}`;
              resourceplan.respstarttime=taskStartDate
              resourceplan.respendtime=taskEndDate
            }else if(resourceplan.resinputtype==2){
              taskStarttime.setDate(taskStarttime.getDate() - resourceplan.resprepareday);
              const taskStartYear = taskStarttime.getFullYear();
              const taskStartMonth = taskStarttime.getMonth() + 1;
              const taskStartDay = taskStarttime.getDate();
              const taskStartDate = `${taskStartYear}/${taskStartMonth}/${taskStartDay}`;
              resourceplan.respstarttime=taskStartDate
              resourceplan.respendtime=taskStartDate
            }else if(resourceplan.resinputtype==3){
              taskEndtime.setDate(taskEndtime.getDate() - resourceplan.resprepareday);
              const taskEndYear = taskStarttime.getFullYear();
              const taskEndMonth = taskStarttime.getMonth() + 1;
              const taskEndDay = taskStarttime.getDate();
              const taskEndDate = `${taskEndYear}/${taskEndMonth}/${taskEndDay}`;
              resourceplan.respstarttime=taskEndDate
              resourceplan.respendtime=taskEndDate
            }else {
              resourceplan.respstarttime=this.convertDateYMD(resourceplan.respstarttime)
              resourceplan.respendtime=this.convertDateYMD(resourceplan.respendtime)
              
            }


            
            resourcePlans.push(resourceplan);
          });
        }
      });
      }


      // const resultArray=this.mergeObjectsByProperty(resourcePlans,'resname')
      // console.log('resultArray',resultArray)

      return resourcePlans
    },

    mergeObjectsByProperty(arr, prop) {
      //要保留其他属性
      let resultMap = {};
      arr.forEach(item => {
        if (resultMap[item[prop]]) {
          resultMap[item[prop]].num += item.num;
          // 保留其他属性
          for (let key in item) {
            if (key !== prop && key !== 'num') {
              resultMap[item[prop]][key] = item[key];
            }
          }
        } else {
          resultMap[item[prop]] = { [prop]: item[prop], num: item.num, ...item }; // 保留其他属性
        }
      });
      let resultArray = Object.values(resultMap);
      return resultArray;
    },

    syncScroll(e){
      const left = this.$refs.leftrespname;
      left.scrollTop=e.target.scrollTop
      // const right = this.$refs.right;
      // if (event.target === left) {
      //   right.scrollTop = left.scrollTop;
      // } else {
      //   left.scrollTop = right.scrollTop;
      // }
    },
  },
};
</script>

<style lang="less" scoped>

  .table-thead {
    position: sticky;
    top: 0;
    background-color: #f1f1f1;
    z-index: 1;
  }
  div.text, div.text1 {
    font-size: 12px;
    border-collapse: collapse;
    // width:auto;
    border:0;
    box-sizing: border-box;
    height:calc(100vh - 118px);
    max-height:calc(100vh - 118px);
    overflow-y:auto;
    div {
      // border:0.5px solid gray;
      border-collapse: collapse;
      box-sizing: border-box;
    }
    table {
      border-collapse: collapse;
      border:0;
      border-spacing: 0px;
      padding:0;
      box-sizing: border-box;
      text-align: center;

      .table-thead {
        position: sticky;
        top: 0;
        background-color: #f1f1f1;
        z-index: 1;
      }
    }

    tbody {
      // border-collapse: collapse;
      border:0;
      border-spacing: 0px;
      padding:0;
      box-sizing: border-box;
    }

    tr {
      // border-collapse: collapse;
      border:0;
      border-spacing: 0px;
      padding:0;
      box-sizing: border-box;
    }
    
    td {
      // border-collapse: collapse;
      border:gray solid 1px;
      border-spacing: 0px;
      padding:0;
      box-sizing: border-box;
      font-size: 12px;
      text-align: center;
      border-bottom:none;
      // height:25px;


      input {
        outline:none;
        padding:0;
        border-collapse: collapse;
        border:0px;
        line-height: 23px;
        font-size: 12px;
        text-align: center;
        margin:0px;
      }

      button {
        background: none;
        border:none;
        padding:0;
        margin:0;
        display: inline;
        height:17px;
        border-collapse: collapse;
      }

      i {
        font-size: 10px;
        padding:0;
        margin:0;
        border-collapse: collapse;
      }

      h4 {
        margin: 10px 0 10px 10px;
      }

    }
  }

  .text::-webkit-scrollbar {
    // width: 10px; /* 设置滚动条宽度 */
    height: 10px; /* 设置滚动条高度 */
  }

  .text1::-webkit-scrollbar {
    width: 5px;
    background: rgb(255, 255, 255);
  }

  .resOptionButton {
    background-color: rgb(145, 170, 252);
    border:solid 0.5px rgb(145, 170, 252);
  }

  .selectedDateResPlanContainer {
    width:calc(100% - 200px);
    position:absolute;
    top:100px;
    left:100px;
    z-index:100;
    background-color: #fff;

  }










</style>