<template>
  <div style="display: flex;">
    <el-col style="width: 250px; border-right: 1px solid rgba(200, 200, 200, 0.9);
      flex-shrink: 0; text-align: left; height: calc(100vh - 100px);">
      <div style="height: 100%;">
        <div v-show="showFilterFriend">
          <input type="text" placeholder="搜索" class="search-input" v-model="searchFriendsText" @change="getSearchFriends">
          <i class="el-icon-search" 
            style="margin: 6px 0 0 -193px;position: absolute;">
          </i>         
          <button  @click="addFriendship($event)" class="search-button" v-if="!showSearchFriends">
            +
          </button>
          <button  @click="cancelSearchFriends()" class="search-button" v-if="showSearchFriends" style="font-size:10px;">
            取消
          </button>
        </div>
        <div v-show="showAddFriend">
          <input type="text" v-model="searchText" placeholder="用户名/手机号/邮箱地址" class="search-input">
          <i class="el-icon-user" 
            style="margin: 6px 0 0 -195px;position: absolute;">
          </i>
          <i class="el-icon-circle-close" v-show="searchText" @click="clearInput" 
            style="margin: 7px 0 0 -20px;position: absolute;">
          </i>
          <button style="float: right; margin-right: 10px; line-height: 30px; border:none; background-color: rgb(255, 255, 255);
            padding:0px" @click="cancelAddFriendship">
            取消
          </button>
        </div>
        <div v-show="showSearchText" @click="gotoFriend($event)" style="background-color: rgb(219, 217, 217);height: 40px;
          line-height: 40px; margin-right:10px; margin-top:5px;" ref="searchtext">
          <i class="el-icon-search"></i>
          <label>
            搜索：{{ searchText }}
          </label>
        </div>
        <FriendInfo v-if="showFriendInfoMenu" :parentNode="parentNode" :event="event" :searchUser="searchUser"
          @addFriendship="addFriendshipTo">

        </FriendInfo>    
        <AddFriend v-if="showApplyAddFriend" @cancelApply="cancelApply" :friendNickname="searchUser.nickname"
        :applyText="`我是${userinfo.nickname}`" @sendAddFriendApply="sendAddFriendApply">  

        </AddFriend>
        <PassFriendApply v-if="showPassFriendApply" :friendNickname="applyFriendNickname"
          @passFriendApply="passFriendApply">

        </PassFriendApply>
        <!-- :applyText="" -->



        <!-- <br> -->
        <div style=" overflow:auto; height: calc(100% - 100px); margin-top:10px;" v-show="showFriends">
          <ul style="text-align: left; margin: 0px; padding: 0px;">
            <!-- <li @click="handleUsersManage" v-show="!showSearchFriends">
              <table>
                <tr >
                  <td rowspan="2">
                    <img src="../../icon/connect/用户管理.png" alt="av" :style="{ width: '40px', height: '40px', borderRadius: '5%' }">
                  </td>
                  <td>&nbsp;&nbsp;用户管理</td>
                </tr>
              </table>             
            </li> -->
            <li @click="handleNewApply" v-show="!showSearchFriends">
              <table>
                <tr >
                  <td rowspan="2">
                    <img src="../../icon/connect/addfriend1.png" alt="av" :style="{ width: '40px', height: '40px', borderRadius: '5%' }">
                  </td>
                  <td>&nbsp;&nbsp;新的朋友</td>
                </tr>
              </table>             
            </li>
            <li v-for="friendship in friendships" :key="friendship.id" @click="selectFriend(friendship)" style="text-align: left;"
             v-show="!showSearchFriends">
              <table>
                <tr>
                  <td rowspan="2">
                    <img :src="friendship.avatarUrl" alt="av" :style="{ width: '40px', height: '40px', borderRadius: '5%' }"
                      v-if="friendship.avatarUrl!==undefined">
                    <div v-if="friendship.avatarUrl==undefined" class="friendmsg-altavatar">
                     {{ showAltNickname(friendship.friend_nickname) }}
                    </div>
                  </td>
                  <td>&nbsp;&nbsp;{{ friendship.friend_commentname }}</td>
                </tr>
              </table>
            </li>
            <li v-show="showSearchFriends" 
              v-for="friendship in searchFriendships" :key="friendship.friend_id" @click="selectFriend(friendship)" style="text-align: left;">
              <table>
                <tr>
                  <td rowspan="2">
                    <img :src="friendship.avatarUrl" alt="av" :style="{ width: '40px', height: '40px', borderRadius: '5%' }"
                      v-if="friendship.avatarUrl!==undefined">
                    <div v-if="friendship.avatarUrl==undefined" class="friendmsg-altavatar">
                     {{ showAltNickname(friendship.friend_nickname) }}
                    </div>
                  </td>
                  <td>&nbsp;&nbsp;{{ friendship.friend_commentname }}</td>
                </tr>
              </table>
            </li>
          </ul>
        </div>
      </div>
    </el-col>
    <el-col style="border-right: 1px solid rgba(200, 200, 200, 0.9); width: calc(100% - 300px); height: calc(100vh - 100px);">
      <div v-show="optionView==1">
        <label>woshiyonghuguanlimokuai</label>
      </div>
      <div class="friendinfo-container" style="text-align: center; width: 100%;" v-show="optionView==3">
        <table class="friendtable">
            <tr>
              <td rowspan="4">
                <img :src="avatarUrl" alt="Avatar" :style="{ width: '75px', height: '75px', borderRadius: '10%' }" v-if="avatarUrl!==undefined"> 
                <div v-if="avatarUrl==undefined" class="friendmsg-altavatar1">
                 {{ showAltNickname(friendNickName) }}
                </div>
              </td>
              <td style="width: 10px;" rowspan="4"></td>
              <td style="height: 25px;font-size: 16px; color: rgb(22, 22, 22); padding-top: 15px;width:150px;">
                {{ friendCommentName }}
              </td>
              <td style="width: 10px;" rowspan="4"></td>
              <td style="width: 100px; ">
                <button style="font-size: 25px; float: right; height: 25px; line-height: 25px;
                  background-color: rgb(255,255,255); border:none;">
                  <i class="el-icon-more" style="color: rgb(122, 122, 122);"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                昵称:&nbsp;&nbsp;{{ friendNickName }}
              </td>
              <td style="width:150px;">
                账号:&nbsp;&nbsp;{{ account }}
              </td>
            </tr>
            <!-- <tr>
              <td>
                账号:&nbsp;&nbsp;{{ account }}
              </td>
            </tr> -->
            <tr>
              <td>
                邮箱:&nbsp;&nbsp;{{ email }}
              </td>
              <td>
                电话:&nbsp;&nbsp;{{ telephone }}
              </td>
            </tr>
            <!-- <tr>
              <td></td>
              <td>
                电话:&nbsp;&nbsp;{{ telephone }}
              </td>
            </tr> -->
            <tr>
              <td colspan="2">
                地区:&nbsp;&nbsp;{{ friendlocation }}
              </td>
            </tr>
            <tr style="height:10px;"></tr>
            <tr style="height:10px; border-top: 0.5px solid rgb(234, 234, 234);"></tr>
            <tr style="height:30px">
              <td style="width: 75px;font-size: 16px; line-height:25px;">备注名：</td>
              <td style="width: 10px;" ></td>
              <td style="font-size: 16px; line-height:25px; color: rgb(22, 22, 22); ">{{ friendCommentName }}</td>
            </tr>
            <tr style="height:30px;">
              <td style="width: 75px;font-size: 16px; line-height:25px;">来源：</td>
              <td style="width: 10px;" ></td>
              <td style="font-size: 16px; line-height:25px; color: rgb(22, 22, 22); ">{{ friendOrigin }}</td>
            </tr>
            <tr style="height:10px; border-bottom: 0.5px solid rgb(234, 234, 234);"></tr>
            <tr style="height:10px;"></tr>
            <tr style="height:37px;">
              <td style="text-align: center;" colspan="5">
                <button style="width: 120px; line-height: 37px; font-size: 16px; border:none; 
                  background-color: rgb(56, 205, 127); color: rgb(255, 255, 255); border-radius: 5px;" @click="sendMsg">
                  发消息
                </button>
              </td>
            </tr>
        </table>
      </div>
      <div v-show="optionView==2">
        <h4 style="text-align: left; line-height: 45px; height: 45px; margin: 0px; padding-left:10px; 
          border-bottom: solid rgb(205, 205, 205) 1px;">
          新的朋友
        </h4>
        <div>
          <ul style="margin: 0 20px 0 20px;padding: 0; font-size: 14px;">
            <li v-for="friendApply in friendApplies" :key="friendApply.id" 
              style="padding: 5px 0 5px 0; border-bottom: solid rgb(205, 205, 205) 1px;">
              <table style="width: 100%;">
                <tr  class="flex-container">
                  <td rowspan="2" style="width: 50px; padding-top: 10px;">
                    <img :src="friendApply.avatarUrl" alt="av" v-if="friendApply.avatarUrl!==undefined"
                      :style="{ width: '50px', height: '50px', borderRadius: '5%'}">
                    <div v-if="friendApply.avatarUrl==undefined" class="friendmsg-altavatar2">
                     {{ showAltNickname(friendApply.applyer_nickname) }}
                     <!-- {{ friendApply.nickname }} -->
                    </div>
                  </td>
                  <td style="text-align: left;">
                    &nbsp;&nbsp;{{ friendApply.applyer_nickname }}
                  </td>
                  <td  rowspan="2" style="text-align: right;"> 
                    <div v-if="friendApply.applystatus=='apply'">
                      <button  class="centered-button" style="margin-right: 20px;"  @click="handleAcceptApply(friendApply)">
                        接受
                      </button>
                      <button  class="centered-button" @click="handleRejectApply(friendApply)">
                        拒绝
                      </button>                                            
                    </div>
                    <label style=" color: rgb(194, 194, 194);" v-if="friendApply.applystatus=='accept'">已接受</label>
                    <label style=" color: rgb(194, 194, 194);" v-if="friendApply.applystatus=='reject'">已拒绝</label>
                  </td>
                </tr>
                <tr>
                  <td  style="text-align: left; color: rgb(194, 194, 194);">&nbsp;&nbsp;{{ friendApply.applyer_msg }}</td>
                </tr>
              </table>
            </li>
          </ul>
        </div>
      </div>
    </el-col>
  </div>
</template>

<script>
import axios from 'axios'
import AddProjectMenu from '../menu/task/AddProjectMenu.vue'
import EditProject from '../menu/task/EditProject.vue'
import FriendInfo from '../menu/connect/FriendInfo.vue'
import PassFriendApply from '../menu/connect/PassFriendApply.vue'
import AddFriend from '../menu/connect/AddFriend.vue'
import io from 'socket.io-client'
import { eventBus } from '../../main.js'
import { mapState , mapMutations} from 'vuex'

export default {
  components: {
    AddProjectMenu,
    AddFriend,
    FriendInfo,
    PassFriendApply
  },
  data () {
    return {
      friendships:[],
      searchFriendships:[],
      messages: [
      ],
      searchText: '',
      searchFriendsText:'',
      newMessage: {},
      newText:'',
      showEmojiPicker: false,
      showFilePicker: false,
      chatto: '王小二',
      socket: null,
      username:'',
      avatarUrl: '',
      avatarImgUrl:[],
      friendName:'',
      friendNickName:'',
      friendlocation:'',
      friendAcount:'',
      friendshipId:'',
      account:'',
      email:'',
      telephone:'',
      friendCommentName:'',
      friendOrigin:'通过电话号码添加',
      showFriendInfo:false,
      showAddFriendshipMenu:false,
      event:null,
      showAddFriend: false,
      showFilterFriend:true,
      showSearchText:false,
      showFriends:true,
      showSearchFriends:false,
      optionView:0,
      showFriendInfoMenu:false,
      parentNode:null,
      searchUser:null,
      userinfo:null,
      showApplyAddFriend:false,
      showNewApply:false,
      applyingFriendships:[],
      applyOption:'',
      friendApplies: [],
      showPassFriendApply:false,
      applyFriendNickname:'',
      // showFriendApplyOption: true,
      applyFriendInfo:null,
      handlingFriendApply:null,
      selectedFriendship:null,

    }
  },

  watch :{
      searchText: function(){
        if(!this.searchText=='')
        this.showSearchText=true
      },
      applyOption: function(){
        if(this.applyOption==''&this.showApplyAccept) {
          this.showApplyOption=false
        }else {
          this.showApplyOption=true
        }
      }
    },

  methods: {
    ...mapMutations({
      switchMenuMask: 'switchMenuMask',
      // updateUser: 'updateUser'
    }),

    //通过好友申请的函数
    passFriendApply(commentName){
      const applyFriend=this.applyFriendInfo
      const newFriendship={user_id:this.userinfo.id, friend_id:applyFriend.applyer_id,friend_lastmsg:applyFriend.applyer_msg,
        friend_commentname:commentName,status:'accept',ifshow: 1}
      this.addFriendshipToSql(newFriendship)
      const apply={id:this.handlingFriendApply.id, applystatus:'accept'}
      this.handleFriendApply(apply)
      const applyFriendship={user_id:applyFriend.applyer_id,friend_id:this.userinfo.id, status:'accept'}
      this.updateFriendshipStatus(applyFriendship)
      this.showPassFriendApply=false
    },

    handleAcceptApply(friendApply){
      this.handlingFriendApply=friendApply
      this.showPassFriendApply=true
      this.applyFriendNickname=friendApply.applyer_nickname
      this.applyFriendInfo=friendApply
      
      
    },

    handleRejectApply(friendApply){
      const data={id:friendApply.id, applystatus: 'reject'}
      this.handleFriendApply(data)
      
    },

    handleFriendApply(apply){    //处理好友申请，选择接受或者拒绝
      axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/updatefriendapplystatus',
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: apply
          })
          .then((response)=>{
            // console.log(response.data)
            console.log('处理好友申请')
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
    },


    //修改好友关系的status
    updateFriendshipStatus(friendship){    //处理好友申请，选择接受或者拒绝
      axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/updatefriendshipstatus',
          headers: {
            Authorization: this.token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: friendship
          })
          .then((response)=>{
            // console.log(response.data)
            console.log('修改好友关系状态成功')
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
    },


    handleNewApply(){
      this.showFriendInfo=false
      // this.showNewApply=true
      this.optionView=2
      this.newFriendApply()
    },

    handleUsersManage(){
      this.optionView=1
    },

    newFriendApply(){
      let friendApplies=[]
      this.getFriendAppliesByUsername(this.username,this.token)
      .then((result)=>{
        friendApplies=(result==undefined)?[]:result

        const promises=friendApplies.map((friendApply)=>{
          return this.showPic(friendApply.applyer_avatar)
            .then((result)=>{
              friendApply.avatarUrl=result
            })
        })
        return Promise.all(promises)
      })
      .then(()=>{
        this.friendApplies=friendApplies
      })
    },

    addFriendship(event){
      this.showFriendInfo=false
      this.showFilterFriend=false
      this.showAddFriend=true
      this.showFriends=false 
      
    },

    cancelSearchFriends(){
      this.showSearchFriends=false
      this.searchFriendsText=''
    },

    addFriendshipTo(){
      this.showFriendInfoMenu=false
      this.showApplyAddFriend=true
      this.switchMenuMask()
    },


    clearInput() {
      this.searchText = '';
      this.showSearchText=false
    },

    cancelAddFriendship(){
      this.searchText=''
      this.showAddFriend=false
      this.showFilterFriend=true
      this.showSearchText=false
      this.showFriends=true
    },

    selectFriend(friendship){
      this.optionView=3
      this.selectedFriendship=friendship

      // this.showNewApply=false
      // this.showFriendInfo=true
      this.optionView=3
      this.avatarUrl=friendship.avatarUrl
      this.account=friendship.friend_username
      this.email=friendship.friend_email
      this.telephone=friendship.friend_telephone
      this.friendNickName=friendship.friend_nickname
      this.friendlocation=friendship.friend_location
      this.friendCommentName=friendship.friend_commentname
      this.friendshipId=friendship.id
      
    },

    sendMsg(){

      this.$router.push({
        path:'/home/friendshipgroup/msgrecord/' + this.friendshipId,
        query:{data:this.selectedFriendship}
      });

    },
    
        //以下是处理编辑菜单的函数
    gotoFriend(event) {
      let searchUser;
      this.getUserInfoBySearchText(this.searchText)
        .then(result => {
          searchUser = result;
          return this.showPic(searchUser.avatar);
        })
        .then(avatarUrl => {
          searchUser.avatarUrl = avatarUrl;
          this.searchUser = searchUser;
          this.showFriendInfoMenu=true
        })
        .catch(error => {
          console.log(error?.response?.data?.message || 'An error occurred.');
        });

      
      this.switchMenuMask()
      this.event={...event}  
      const element=this.$refs.searchtext
      const { top, right } = this.getTopRightPosition(event, element);
      this.parentNode ={clientY:top,clientX:right+20}
      eventBus.$on('closeContexMenu', () => {
        this.showFriendInfoMenu=false
      })
    },

    getSearchFriends(){
      this.searchFriendships=this.friendships.filter((friend)=>{
        if(friend.friend_nickname.includes(this.searchFriendsText)||friend.friend_username.includes(this.searchFriendsText)||friend.friend_commentname.includes(this.searchFriendsText)){
          return friend
        }
      })
      this.showSearchFriends=true
    },

    getTopRightPosition(event,element) {
      const rect = element.getBoundingClientRect();
      const top=rect.top
      const right=rect.right
      // const top = event.clientY - rect.height;
      // const right = event.clientX + rect.width;
      return { top, right };
    },

    cancelApply(){
      this.showApplyAddFriend=false
    },

    sendAddFriendApply(applyText,commentName){
      const now=new Date()
      const created_at=now.toISOString().slice(0, 19).replace('T', ' ')
      const myNewFriendship={user_id:this.userinfo.id,friend_id: this.searchUser.id,friend_lastmsg:applyText,
        friend_commentname: commentName,status:'apply',created_at:created_at, ifshow:1}
      const myFriendApply={applyer_id: this.userinfo.id,applyer_msg:applyText,applystatus:'apply',applyto:this.searchUser.username}
      this.addFriendshipToSql(myNewFriendship)
      this.addFriendApplyToSql(myFriendApply)
      this.showApplyAddFriend=false
      this.searchText=''
      this.showSearchText=false
      this.showAddFriend=false
      this.showFilterFriend=true
      this.showFriends=true
    },


    // 向数据库添加一个好友关系
    addFriendshipToSql(newFrienship){
      const token = localStorage.getItem('token')
      axios({
        method:'post',
        url: 'https://api2.itaskid.com' + '/my/friendship',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newFrienship
        })
        .then((response)=>{
          
          console.log('添加好友关系成功')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
        })
    },

    // 向数据库添加一个好友申请
    addFriendApplyToSql(newFriendApply){
      const token = localStorage.getItem('token')
      axios({
        method:'post',
        url: 'https://api2.itaskid.com' + '/my/friendapply',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newFriendApply
        })
        .then((response)=>{
          
          console.log('添加好友申请成功')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
        })
    }, 



    async getFriendships(id,token) {
      try {
        const data = { user_id: id };
        const response = await axios.get('https://api2.itaskid.com' + '/my/friendships', {
          headers: {
            Authorization: token,
            // 'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        if (Array.isArray(response.data)) {
          return response.data
        } else {
          alert('账号未认证，无法获取数据！');
          window.location.href = "https://www.itaskid.com" + "/login";
          return
        }

      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.')
      }
    },

    async getFriendshipsByUserId(id,token) {
      try {
        const data = { user_id: id };
        const response = await axios.get('https://api2.itaskid.com' + '/my/friendshipsbyuserid', {
          headers: {
            Authorization: token,
            // 'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
        if (Array.isArray(response.data)) {
          return response.data
        } else {
          // alert('账号未认证，无法获取数据！');
          // window.location.href = "https://www.itaskid.com" + "/login";
          return []
        }

      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.')
      }
    },

    // async getFriendAppliesByUsername(username,token) {
    //   try {
    //     const data = { applyto: username };
    //     const response = await axios.get('https://api2.itaskid.com' + '/my/friendapplies', {
    //       headers: {
    //         Authorization: token,
    //         // 'Content-Type': 'application/x-www-form-urlencoded'
    //       },
    //       params: data
    //     });
    //     if (Array.isArray(response.data)) {
    //       return response.data
    //     } else {
    //       alert('账号未认证，无法获取数据！');
    //       window.location.href = "https://www.itaskid.com" + "/login";
    //       return
    //     }
    //   } catch (error) {
    //     console.log(error?.response?.data?.message || 'An error occurred.')
    //   }
    // },

    async getFriendAppliesByUsername(username, token) {
      try {
        const data = { applyto: username };
        const response = await axios.get('https://api2.itaskid.com' + '/my/friendapplies', {
          headers: {
            Authorization: token,
            // 'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
        });
    
        if (Array.isArray(response.data) && response.data.length > 0) {
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
        return [];
      }
    },

    async getUserInfoBySearchText(text) {
      const token=localStorage.getItem('token')
      try {
        const data = {searchText:text};
        const response = await axios.get('https://api2.itaskid.com' + '/my/userinfobysearchtext', {
          headers: {
            Authorization: token,
            // 'Content-Type': 'application/json'
          },
          params: data
        });
        if(response.data.status==0){
          return response.data.data
        }else {
          alert('该用户不存在');
        }
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.')
      }
    },



    getUserInfo(username){
      const key=`user|${username}`
      const userString=localStorage.getItem(key)
      return JSON.parse(userString)
    },
    showPic1(){
      const token = localStorage.getItem('token')
      const data={url:'1693785299776-微信图片_20190503231014.jpg'}
      axios({
          method:'get',
          // responseType: 'blob',
          responseType: 'arraybuffer',
          
          url: 'https://api2.itaskid.com' + '/my/showavatar',
          headers: {
            Authorization: token,
            // 'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: data
          })
        .then((response)=>{
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType});
          const blobUrl = window.URL.createObjectURL(blob);
          this.avatarUrl=blobUrl
        })
        .catch(function (error) {
          // console.log(error?.response?.data?.message || 'An error occurred.');
          console.log(error);
        })
    },

    // async showPic(imgname) {
    //   const token = localStorage.getItem('token');
    //   const data = { url: imgname };
    //   try {
    //     const response = await axios({
    //       method: 'get',
    //       responseType: 'arraybuffer',
    //       url: 'https://api2.itaskid.com' + '/my/showavatar',
    //       headers: {
    //         Authorization: token,
    //       },
    //       params: data,
    //     });
    //     const contentType = response.headers['content-type'];
    //     const blob = new Blob([response.data], { type: contentType });
    //     const blobUrl = window.URL.createObjectURL(blob);
    //     return blobUrl;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    async showPic(imgname) {
      if(imgname==null||imgname=='null'||imgname==''){
        return undefined
      } else {
        const token = localStorage.getItem('token');
        const data = { url: imgname };
        try {
          const response = await axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: 'https://api1.itaskid.com' + `/my/showavatar`,
            headers: {
              Authorization: token,
            },
            params: data,
          });
          const contentType = response.headers['content-type'];
          const blob = new Blob([response.data], { type: contentType });
          const blobUrl = window.URL.createObjectURL(blob);
          return blobUrl;
        } catch (error) {
          console.log(error);
        }
      }
    },

    isBlob(obj) {
      return obj instanceof Blob;
    }

  },


  computed: {
    showAltNickname(){
      return(nickname)=>{
        const matchResult = nickname.match(/[\u4e00-\u9fa5]{1,2}(?=[^\u4e00-\u9fa5]*$)/);
        const altNikname = matchResult ? matchResult[0] : ''
        return altNikname
      }
    },
    async showAvatar() {
      try {
        const avatarname = '1693785299776-微信图片_20190503231014.jpg';
        const avatarUrl = await this.showPic(avatarname);
        return avatarUrl;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    showTimestamp(){
      return(index)=> {
        if(index==0) {
          return true
        } else {
          return false
        }
      }
    },

    
    formatTimestamp() {
      return (timestamp)=>{
        const date = new Date(timestamp);
        const now = new Date();
        const diff = (now - date) / (1000 * 60 * 60 * 24); // 计算时间差，单位为天
      
        if (diff < 1) {
          // 如果是今天，则只显示时间
          return date.toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'});
        } else if (diff < 2) {
          // 如果是昨天，则显示成‘昨天 08:25’
          return `昨天 ${date.toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'})}`;
        } else if (diff < 7) {
          // 如果是本周，但又不是今天和昨天，则显示成‘周三 08:25’
          const weekdays = ['日', '一', '二', '三', '四', '五', '六'];
          return `周${weekdays[date.getDay()]} ${date.toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'})}`;
        } else {
          // 如果不是本周，则显示成‘2023年5月20日 08:25’
          return date.toLocaleString('zh-CN', {year: 'numeric', month: '2-digit', day: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit'});
        }
      }
      
     },

    filteredChats() {
      // 根据搜索框的内容过滤聊天列表
      return this.chats.filter(chat => {
        return chat.name.toLowerCase().includes(this.searchText.toLowerCase());
      });
    },
    
  },


  mounted(){
    const token = localStorage.getItem('token')
    const username=localStorage.getItem('username')
    const userId=localStorage.getItem('userId')
    const userinfo=this.getUserInfo(username)
    this.userinfo=userinfo
    this.token=token
    this.username=username
    let friendships=[]
    this.getFriendshipsByUserId(userId,token)
      .then((result)=>{
        friendships=result
        if(friendships){

          const promises=friendships.map(async (friendship)=>{
            const result=await this.showPic(friendship.friend_avatar)
            friendship.avatarUrl=result
          })
          return Promise.all(promises)
        }else {
          return []
        }
      })
      .then(()=>{
        if(friendships){
          this.friendships=friendships.filter(friendship=>{
            return friendship.status=='accept'
          })

        } else {
          this.friendships=[]
        }
      })
      
      // let friendApplies
      // this.getFriendAppliesByUsername(username,token)
      // .then((result)=>{
      //   friendApplies=result
      //   const promises=friendApplies.map((friendApply)=>{
      //     return this.showPic(friendApply.applyer_avatar)
      //       .then((result)=>{
      //         friendApply.avatarUrl=result
      //       })
      //   })
      //   return Promise.all(promises)
      // })
      // .then(()=>{
      //   this.friendApplies=friendApplies
      // })



      this.socket = io("https://api2.itaskid.com")

    
      // 监听连接成功事件
      this.socket.on('connect', () => {
        console.log('Connected to socket.io server')
      });
      
      // 监听服务器发送的消息
      this.socket.on('message', (message) => {
        this.messages.push(message)
      });

    

  },

  beforeDestroy() {
    // 断开socket.io连接
    if (this.socket) {
      this.socket.disconnect();
    }
  },
}
</script>

<style lang="less" scoped>

.name {
  display: block;
}

.last-message {
  display: block;
}

// .el-col {
//   max-height: 100% !important;
//   overflow: auto !important;
// }


li {
    list-style: none;
  }

  li:hover {
    background-color: rgb(200, 200, 200);
    cursor: pointer;
  }

.context-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.el-main {
  line-height: 20px !important;
}


.menu-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 250, 250, 0.5);
  z-index: 99;
}
::-webkit-scrollbar {
  width: 8px;
  height: 50px;
  background: none;
}

.info {
  display: inline;
  flex-direction: column;
}

.avatar {
  margin-right: 10px;
}

.avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.message-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .message {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    flex: 1; /* 占据剩余空间 */
  }

  .right {
  // text-align: right;
  float: right;
  background-color: #eaf6ff;
  }
  
  .left {
    // text-align: left;
    float: left;
    background-color: #f2f2f2;
  }

  .msg-time {
    display: flex;
    justify-content: center;
    background-color: rgb(218, 218, 218);
    width: fit-content;
    margin: 0 auto;
  }

  .friendinfo-container {
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // height: 100%;
    margin-top: 100px;
  }

  .friendtable {
    border-collapse: collapse;
    margin: 0 auto;
    
    tr {
      height: 25px;
      line-height: 15px;
    }

    td {
      font-size: 12px;
      text-align: left;
      color: rgb(186, 186, 186)
    }
  }

  .input-container {
    position: relative;
  }

  .delete-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    // background-image: url('path/to/delete-icon.png');
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }



  .centered-button {
    // display: inline-block;
    vertical-align: middle;
  }

  .search-input {

    border:none;
    background-color: rgb(236, 236, 236);
    border-radius: 5px;

    height: 30px; 
    width: 195px; 
    padding:0px;
    text-indent: 20px;
  }

  .search-input:focus {
    outline: 0.5px solid rgba(64, 158, 255,0.7) !important;

  }

  .search-button {
    border:none;
    background-color: rgb(236, 236, 236);
    border-radius: 5px;
    float: right; 
    margin-right: 10px; 
    height: 30px;
    width: 40px;
  }

  .search-button:hover {
    color:rgba(64, 158, 255,0.7);
    background-color: rgb(226, 226, 226);
  }

  .friendmsg-altavatar {
    width: 40px;
    height: 40px;
    border-radius: 5%;
    background: rgb(144, 82, 245);
    color:rgb(255, 255, 251);
    font-size: 14px;
    line-height: 40px;
    text-align: center;
  }

  .friendmsg-altavatar2 {
    width: 50px;
    height: 50px;
    border-radius: 5%;
    background: rgb(144, 82, 245);
    color:rgb(255, 255, 251);
    font-size: 16px;
    line-height: 50px;
    text-align: center;
  }

  .friendmsg-altavatar1 {
    width: 75px;
    height: 75px;
    border-radius: 10%;
    background: rgb(144, 82, 245);
    color:rgb(255, 255, 251);
    font-size: 30px;
    line-height: 75px;
    text-align: center;
  }
</style>