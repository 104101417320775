<template>
  <div class="project-container">
    <el-col style="width: 70%;  border-right: 1px solid rgba(200, 200, 200, 0.9);">
      <div style="height: 85px;">
        <h3 class="selected-proj-name" style="text-align: left;">{{projectName}}</h3>
        <input type="text" class="input-add-proj"
          @keydown.enter="addTaskFromInput"
          :placeholder="placeholderStr"
          v-model="newTaskNameLv1">
        <div style="display: inline; float: right;">
          <select v-model="selectedOption">
            <option value="全部">全部</option>
            <option value="今天">今天</option>
            <option value="本周">本周</option>
            <option value="本月">本月</option>
          </select>
          <label for="" style="margin-left:10px;">未完成</label>
          <input type="checkbox" style="margin-right: 10px;">
        </div>
      </div>
      <div>
        <label for="" style="text-align: left;">任务名称</label>
        <label for="" style="float: right; width: 100px; text-align: center; padding: 0; margin: 0;">前置任务</label>
        <label for="" style="float: right; width: 150px; text-align: center; padding: 0; margin: 0;">结束时间</label>
        <label for="" style="float: right; width: 150px; text-align: center; padding: 0; margin: 0;">开始时间</label>
        <label for="" style="float: right; width: 60px; text-align: center; padding: 0; margin: 0;">工期</label>
      </div>
      <!-- //style="height: calc(100vh - 180px);" -->
      <div class="alltasks-container">
        <div class="project-container-leftcol">     
        <!-- <EditTaskMenu
          v-if="showTaskEdit" :selectedTaskAcls="selectedTaskAcls"
          ref="edittaskmenu"
          :selectedTask="selectedTask"
          @cancelEditTask="cancelEditTask"
          @closeEditTask="closeEditTask" :parentNode="parentNode" @deleteTask="deleteTask()"
          @taskeditMenuitemClick="taskeditMenuitemClicked">
        </EditTaskMenu> -->
        <EditTaskMenu
          v-if="showTaskEdit" 
          ref="edittaskmenu"
          :selectedTask="selectedTask" :usernogroup="usernogroup" :selectedTaskAcls="selectedTaskAcls"
          @cancelEditTask="cancelEditTask" :TaskeditMenuitems="TaskeditMenuitems" @updateTaskAcl="updateTaskAcl"
          @closeEditTask="closeEditTask" :parentNode="parentNode" @deleteTask="deleteTask()"
          @taskeditMenuitemClick="taskeditMenuitemClicked">
        </EditTaskMenu>
        <ul v-for="task1 in tasklist1" :key="task1.idft" ref="task-ul" class="ul-task1" 
         @contextmenu.prevent="taskEditMenu($event,task1)">  
          <div class="task-container" v-if="ifShowTask(task1)">
            <button v-show="hasNoDoneChild(task1)&&!task1.expanded" @click="toggleNode($event,task1)">
              <i class="el-icon-arrow-right"></i>
            </button>
            <button v-show="hasNoDoneChild(task1)&&task1.expanded" @click="toggleNode($event,task1)">
              <i class="el-icon-arrow-down"></i>
            </button>
            <button  v-show="!hasNoDoneChild(task1)">
            </button>
            <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task1)">
            <input type="text" class="input-taskname" v-model="task1.name"  
              @keyup.enter="addBroTask($event,task1)" @change="updateTaskOfSql(task1)">
            <input type="text" class="input-taskduration" v-model="task1.duration" @change="updateTaskOfSql(task1)">
            <input type="text" class="input-taskstarttime" v-model="task1.starttime" @change="updateTaskOfSql(task1)">
            <input type="text" class="input-taskendtime" v-model="task1.endtime" @change="updateTaskOfSql(task1)">
            <input type="text" class="input-taskpretask" v-model="task1.pretask" @change="updateTaskOfSql(task1)">
          </div>
          <li  v-for="task2 in taskListWithLevel(2,task1)" :key="task2.idft" ref="task-li" v-show="task1.expanded"
          @contextmenu.prevent="taskEditMenu($event,task2)" style="padding-left: 10px;">
            <div  class="task-container"  v-if="ifShowTask(task2)">
              <button v-show="hasNoDoneChild(task2)&&!task2.expanded" @click="toggleNode($event,task2)">
                <i class="el-icon-arrow-right"></i>
              </button>
              <button v-show="hasNoDoneChild(task2)&&task2.expanded" @click="toggleNode($event,task2)">
                <i class="el-icon-arrow-down"></i>
              </button>
              <button  v-show="!hasNoDoneChild(task2)">
              </button>
              <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task2)">
              <input type="text" class="input-taskname" v-model="task2.name"  
              @keyup.enter="addBroTask($event,task2)" @change="updateTaskOfSql(task2)"> 
              <input type="text" class="input-taskduration" v-model="task2.duration" @change="updateTaskOfSql(task2)">
              <input type="text" class="input-taskstarttime" v-model="task2.starttime" @change="updateTaskOfSql(task2)">
              <input type="text" class="input-taskendtime" v-model="task2.endtime" @change="updateTaskOfSql(task2)">
              <input type="text" class="input-taskpretask" v-model="task2.pretask" @change="updateTaskOfSql(task2)">
            </div>
            <ul v-for="task3 in taskListWithLevel(3,task2)" :key="task3.idft" ref="task-ul" v-show="task2.expanded"
              @contextmenu.prevent="taskEditMenu($event,task3)" style="padding: 0 0 0 10px;">
              <div  class="task-container"  v-if="ifShowTask(task3)">
                <button v-show="hasNoDoneChild(task3)&&!task3.expanded"  @click="toggleNode($event,task3)">
                  <i class="el-icon-arrow-right"></i>
                </button>
                <button v-show="hasNoDoneChild(task3)&&task3.expanded"  @click="toggleNode($event,task3)">
                  <i class="el-icon-arrow-down"></i>
                </button>
                <button  v-show="!hasNoDoneChild(task3)">
                </button>
                <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task3)">
                <input type="text" class="input-taskname" v-model="task3.name" 
                @keyup.enter="addBroTask($event,task3)" @change="updateTaskOfSql(task3)">
                <input type="text" class="input-taskduration" v-model="task3.duration" @change="updateTaskOfSql(task3)">
                <input type="text" class="input-taskstarttime" v-model="task3.starttime" @change="updateTaskOfSql(task3)">
                <input type="text" class="input-taskendtime" v-model="task3.endtime" @change="updateTaskOfSql(task3)">
                <input type="text" class="input-taskpretask" v-model="task3.pretask" @change="updateTaskOfSql(task3)">
              </div>
              <li v-for="task4 in taskListWithLevel(4,task3)" :key="task4.idft"  
                ref="task-li" v-show="task3.expanded"
                @contextmenu.prevent="taskEditMenu($event,task4)" style="padding: 0 0 0 10px">
                <div  class="task-container"  v-if="ifShowTask(task4)">
                  <button v-show="hasNoDoneChild(task4)&&!task4.expanded" @click="toggleNode($event,task4)">
                    <i class="el-icon-arrow-right"></i>
                  </button>
                  <button v-show="hasNoDoneChild(task4)&&task4.expanded" @click="toggleNode($event,task4)">
                    <i class="el-icon-arrow-down"></i>
                  </button>
                  <button  v-show="!hasNoDoneChild(task4)">
                  </button>
                  <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task4)">
                  <input type="text" class="input-taskname" v-model="task4.name" 
                  @keyup.enter="addBroTask($event,task4)" @change="updateTaskOfSql(task4)">
                  <input type="text" class="input-taskduration" v-model="task4.duration" @change="updateTaskOfSql(task4)">
                  <input type="text" class="input-taskstarttime" v-model="task4.starttime" @change="updateTaskOfSql(task4)">
                  <input type="text" class="input-taskendtime" v-model="task4.endtime" @change="updateTaskOfSql(task4)">
                  <input type="text" class="input-taskpretask" v-model="task4.pretask" @change="updateTaskOfSql(task4)">
                </div>
                <ul v-for="task5 in taskListWithLevel(5,task4)" :key="task5.idft"  
                  ref="task-ul" v-show="task4.expanded"
                  @contextmenu.prevent="taskEditMenu($event,task5)"  style="padding: 0 0 0 10px">
                  <div  class="task-container"  v-if="ifShowTask(task5)">
                    <button v-show="hasNoDoneChild(task5)&&!task5.expanded" @click="toggleNode($event,task5)">
                      <i class="el-icon-arrow-right"></i>
                    </button>
                    <button v-show="hasNoDoneChild(task5)&&task5.expanded" @click="toggleNode($event,task5)">
                      <i class="el-icon-arrow-down"></i>
                    </button>
                    <button  v-show="!hasNoDoneChild(task5)">
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </button>
                    <input type="checkbox" class="task-done" @change="toggleTaskDone($event,task5)">
                    <input type="text" class="input-taskname" v-model="task5.name" 
                    @keyup.enter="addBroTask($event,task5)" @change="updateTaskOfSql(task5)" >
                    <input type="text" class="input-taskduration" v-model="task5.duration" @change="updateTaskOfSql(task5)">
                    <input type="text" class="input-taskstarttime" v-model="task5.starttime" @change="updateTaskOfSql(task5)">
                    <input type="text" class="input-taskendtime" v-model="task5.endtime" @change="updateTaskOfSql(task5)">
                    <input type="text" class="input-taskpretask" v-model="task5.pretask" @change="updateTaskOfSql(task5)">
                  </div>
                  <li v-for="task6 in taskListWithLevel(6,task5)" :key="task6.idft" 
                    v-show="task5.expanded"
                    @contextmenu.prevent="taskEditMenu($event,task6)"  style="padding: 0 0 0 10px">
                    <div  class="task-container"  v-if="ifShowTask(task6)">
                      <input type="checkbox" class="task-done" style="margin-left: 26px" @change="toggleTaskDone($event,task6)">
                      <input type="text" class="input-taskname" v-model="task6.name"
                        @keyup.enter.prevent="addBroTask($event,task5)" @change="updateTaskOfSql(task6)" >
                      <input type="text" class="input-taskduration" v-model="task6.duration" @change="updateTaskOfSql(task6)">
                      <input type="text" class="input-taskstarttime" v-model="task6.starttime" @change="updateTaskOfSql(task6)">
                      <input type="text" class="input-taskendtime" v-model="task6.endtime" @change="updateTaskOfSql(task6)">
                      <input type="text" class="input-taskpretask" v-model="task6.pretask" @change="updateTaskOfSql(task6)">
                    </div>
                  </li>
                </ul>
              </li>  
            </ul>
          </li>
        </ul>
        </div>
        
        <div class="projectdone-container" v-if="showTaskDoneContainer">
        <div>
          <button style="border: none; background: none;height: 42px;" @click=showDoneTaskToggle()>
            <i class="el-icon-arrow-right" v-show="!showDoneProject"></i>
            <i class="el-icon-arrow-down" v-show="showDoneProject"></i>
          </button>
          <label for="" style="padding-left: 0;height: 42px;">已完成</label>
        </div>
        <div class="project-container-leftcol" v-show="showDoneProject">
          <ul v-for="task1 in tasklistDone1" :key="task1.idft" ref="task-ul" @click="goToTaskDetail($event,task1)"
            class="ul-task1" 
           @contextmenu.prevent="taskEditMenu($event,task1)">  
            <div class="task-container"  v-if="ifShowTask(task1)">
              <button v-show="hasDoneChild(task1)&&!task1.expanded" @click="toggleNode($event,task1)">
                <i class="el-icon-arrow-right"></i>
              </button>
              <button v-show="hasDoneChild(task1)&&task1.expanded" @click="toggleNode($event,task1)">
                <i class="el-icon-arrow-down"></i>
              </button>
              <button  v-show="!hasDoneChild(task1)">
              </button>
              <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task1)" v-model="task1.done" >
              <input type="text" class="input-taskname" v-model="task1.name" 
                @keyup.enter="addBroTask($event,task1)" @change="updateTaskOfSql(task1)">
            </div>
            <li  v-for="task2 in taskListDoneWithLevel(task1)" :key="task2.idft" ref="task-li" 
            v-show="task1.expanded" @click="goToTaskDetail($event,task2)"
            @contextmenu.prevent="taskEditMenu($event,task2)" style="padding-left: 10px;">
              <div  class="task-container"  v-if="ifShowTask(task2)">
                <button v-show="hasDoneChild(task2)&&!task2.expanded" @click="toggleNode($event,task2)">
                  <i class="el-icon-arrow-right"></i>
                </button>
                <button v-show="hasDoneChild(task2)&&task2.expanded" @click="toggleNode($event,task2)">
                  <i class="el-icon-arrow-down"></i>
                </button>
                <button  v-show="!hasDoneChild(task2)">
                </button>
                <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task2)" v-model="task2.done">
                <input type="text" class="input-taskname" v-model="task2.name"  
                @keyup.enter="addBroTask($event,task2)" @change="updateTaskOfSql(task2)"> 
              </div>
              <ul v-for="task3 in taskListDoneWithLevel(task2)" :key="task3.idft"  
                ref="task-ul" v-show="task2.expanded" @click="goToTaskDetail($event,task3)"
                @contextmenu.prevent="taskEditMenu($event,task3)" style="padding: 0 0 0 10px;">
                <div  class="task-container"  v-if="ifShowTask(task3)">
                  <button v-show="hasDoneChild(task3)&&!task3.expanded"  @click="toggleNode($event,task3)">
                    <i class="el-icon-arrow-right"></i>
                  </button>
                  <button v-show="hasDoneChild(task3)&&task3.expanded"  @click="toggleNode($event,task3)">
                    <i class="el-icon-arrow-down"></i>
                  </button>
                  <button  v-show="!hasDoneChild(task3)">
                  </button>
                  <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task3)" v-model="task3.done">
                  <input type="text" class="input-taskname" v-model="task3.name" 
                  @keyup.enter="addBroTask($event,task3)" @change="updateTaskOfSql(task3)">
                </div>
                <li v-for="task4 in taskListDoneWithLevel(task3)" :key="task4.idft"  
                  ref="task-li" v-show="task3.expanded" @click="goToTaskDetail($event,task4)"
                  @contextmenu.prevent="taskEditMenu($event,task4)" style="padding: 0 0 0 10px">
                  <div  class="task-container"  v-if="ifShowTask(task4)">
                    <button v-show="hasDoneChild(task4)&&!task4.expanded" @click="toggleNode($event,task4)">
                      <i class="el-icon-arrow-right"></i>
                    </button>
                    <button v-show="hasDoneChild(task4)&&task4.expanded" @click="toggleNode($event,task4)">
                      <i class="el-icon-arrow-down"></i>
                    </button>
                    <button  v-show="!hasDoneChild(task4)">
                    </button>
                    <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task4)" v-model="task4.done">
                    <input type="text" class="input-taskname" v-model="task4.name" 
                    @keyup.enter="addBroTask($event,task4)" @change="updateTaskOfSql(task4)">
                  </div>
                  <ul v-for="task5 in taskListDoneWithLevel(task4)" :key="task5.idft"  
                    ref="task-ul" v-show="task4.expanded" @click="goToTaskDetail($event,task5)"
                    @contextmenu.prevent="taskEditMenu($event,task5)"  style="padding: 0 0 0 10px">
                    <div  class="task-container"  v-if="ifShowTask(task5)">
                      <button v-show="hasDoneChild(task5)&&!task5.expanded" @click="toggleNode($event,task5)">
                        <i class="el-icon-arrow-right"></i>
                      </button>
                      <button v-show="hasDoneChild(task5)&&task5.expanded" @click="toggleNode($event,task5)">
                        <i class="el-icon-arrow-down"></i>
                      </button>
                      <button  v-show="!hasDoneChild(task5)">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </button>
                      <input type="checkbox" class="task-done" @change="toggleTaskUndone($event,task5)" v-model="task5.done">
                      <input type="text" class="input-taskname" v-model="task5.name" 
                      @keyup.enter="addBroTask($event,task5)" @change="updateTaskOfSql(task5)" >
                    </div>
                    <li v-for="task6 in taskListDoneWithLevel(task5)" :key="task6.idft" 
                      v-show="task5.expanded" @click="goToTaskDetail($event,task6)"
                      @contextmenu.prevent="taskEditMenu($event,task6)"  style="padding: 0 0 0 10px">
                      <div  class="task-container"  v-if="ifShowTask(task6)">
                        <input type="checkbox" class="task-done" style="margin-left: 26px" @change="toggleTaskUndone($event,task6)"
                          v-model="task6.done">
                        <input type="text" class="input-taskname" v-model="task6.name"
                          @keyup.enter.prevent="addBroTask($event,task5)" @change="updateTaskOfSql(task6)" >
                      </div>
                    </li>
                  </ul>
                </li>  
              </ul>
            </li>
          </ul>
        </div>

        </div>
      </div>

    </el-col>
    <el-col style="width: 30%;">
      <!-- <router-view :selectedTaskTime="showStarttime(selectedTask)"
        @upDateStarttime="upDateStarttime" :selectedTask="selectedTask" :taskContents="taskContents"
        @updateTaskContent="updateTaskContent" @addTaskContent="addTaskContent">
      </router-view> -->
      <div style="height: 85px;">
        <h3 class="selected-proj-name" style="text-align: left;">完成率统计</h3>
      </div>
      <div style="display: inline-block;">
        <el-progress type="circle" :percentage="crOfTasks"></el-progress>
        <div>截止今天</div>
      </div>
      <div style="display: inline-block; margin-left:50px">
        <el-progress type="circle" :percentage="crOfTasks"></el-progress>
        <div>本周完成率</div>
      </div>
      <br><br>
      <div style="display: inline-block;">
        <el-progress type="circle" :percentage="crOfTasks"></el-progress>
        <div>本月完成率</div>
      </div>
      <div style="display: inline-block; margin-left:50px">
        <el-progress type="circle" :percentage="crOfTasks"></el-progress>
        <div>全项目完成率</div>
      </div>
    </el-col>
  </div>
 
</template>

<script>
import axios from 'axios'
import { mapState,mapMutations,mapActions} from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import EditTaskMenu from '../menu/task/EditTaskMenu.vue'
import { eventBus } from '../../main.js'
import dayjs from 'dayjs'
import { nextTick } from 'vue'

export default {
  components: {
    EditTaskMenu
  },
  name: 'TaskProject',
  props: ['idfp','name','selectedProjectRoute','selectedProject','placeholderStr','projectTitle','projectName','myProjects'],
  data(){
    return {
      date:'',
      tasklistall:[],
      newTaskNameLv1:'',
      tasklist:[],
      selectedTasks:[],
      objTest:'',
      hisObj:'',
      nogroupTasklist:[],

      //以下是关于任务编辑右键菜单的数据
      showTaskEdit: false,
      parentNode:null,
      selectedTask:'',

      showTaskDateEdit:false,
      newStarttimeFromDatePicker:'',

      //以下是任务详情内容有关的数据
      taskContents:[],

      //以下是已完成任务相关数据
      showDoneProject: true,
      tasklistDone:[],

      testdoneTasks: [
        { idft: 10, name: 'ms2', level: 1, parent: 'roottask' },
        { idft: 11, name: 'ms3', level: 3, parent: 10 },
        { idft: 12, name: 'ms4', level: 4, parent: 11 },
        { idft: 13, name: 'mshao', level: 5, parent: 1 },
      ],

      TaskeditMenuitems:[
        { id:1,label: '添加子任务' }, 
        {id:2,label:'指派给'},
        { id:3,label: '置顶' },
        { id:4,label: '移动到' },
        { id:5,label: '标签' }, 
        { id:6,label: '复制链接' }, 
        { id:7,label: '创建副本' },
        { id:8,label: '删除' }
      ],

      selectedOption:'全部',
      tasksOfSelectedProject:'',
      crOfTasks: 0,
      rangeOftask: '',
      username:'',

    }
  },
  
  computed: {



    ifShowTask() {
      return (task)=>{
        const taskAcl=this.myTaskAcllist.filter(item=>item.task_idft==task.idft)
        // const isCharger=(taskAcl.length==0)?false:(taskAcl[0].permit_level=='charger'?true:false)
        const isCharger=(taskAcl.length==0)?false:true
        if(this.selectedProject.permit_level=='parter'&&!isCharger) {
          return false
        } else {
          return true
        }
      }
    },

    taskListWithLevel() {
      const id=this.$route.params.idfp
      if(id=='today'||id=='week'){
        return (level,parentTask) => this.tasklist.filter(task => task.parent===parentTask.idft&&task.done===0)
      }else {
        return (level,parentTask) => this.tasklist.filter(task => task.parent===parentTask.idft&&task.level==level&&task.done===0)
      }
      
    },
    taskListDoneWithLevel(){
      return (parentTask) => this.tasklistDone.filter(task => task.parent===parentTask.idft)
    },
    hasNoDoneChild:function() {
      return function(task) {
        const noDoneChilds = this.tasklist.filter(i => i.parent === task.idft && i.done === 0);
        if (noDoneChilds.length < 1) {
          return 0;
        } else {
          return 1;
        }
      }
    },

    showTaskDoneContainer:function(){
      if(this.tasklistDone.length>0) {
        return 1
      }else {
        return 0
      }
    },
    hasDoneChild:function() {
      return function(task) {
        const doneChilds = this.tasklistDone.filter(i => i.parent === task.idft && i.done === 1);
        if (doneChilds.length < 1) {
          return 0;
        } else {
          return 1;
        }
      }
    },
    ...mapState({
      selectProjName: 'selectProjName',
      selectProjGroup: 'selectProjGroup',
      selectProjIdfp: 'selectProjIdfp',
      user:'user'
    }),
    tasklist1: function(){
      const id=this.$route.params.idfp
      if(id=='today'){
        const today=new Date()
        // let tasklist01=this.selectedTasks.filter(item=>item.parent=='roottask'||
        let tasklist01=this.tasklist.filter(item=>item.parent=='roottask'||
        (item.parent!=='roottask'&&this.tasklistall.find(i=>i.idft==item.parent&&(new Date(i.starttime) > today||i.starttime==''))))
        return tasklist01
      }else if(id=='week') {
        const today=new Date()
        today.setHours(0, 0, 0, 0)
        const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6)
        // let tasklist01=this.selectedTasks.filter(item=>item.parent=='roottask'||
        let tasklist01=this.tasklist.filter(item=>item.parent=='roottask'||
        (item.parent!=='roottask'&&this.tasklistall.find(i=>i.idft==item.parent&&(new Date(i.starttime) > oneWeekLater||i.starttime==''))))
        return tasklist01
      }else {
        // let tasklist01 = this.selectedTasks.filter(
          let tasklist01 = this.tasklist.filter(
        function(task) {
          return task.level === 1&&task.done==0
        })
        return tasklist01
      }

    },

    tasklistDone1: function(){
      const doneTasks=this.tasklistDone
      const doneTasks1=[]
      for(var i=0; i<doneTasks.length; i++){
        if(doneTasks[i].level===1){
          doneTasks1.push(doneTasks[i])
        }else {
          const parentTask=doneTasks.find(task=>task.idft===doneTasks[i].parent)
          if(!parentTask) {
            doneTasks1.push(doneTasks[i])
          }
        }
      }
      return doneTasks1
    },

    showStarttime(){
      return (task)=>{
        
        const today=new Date()
        const thisYear=today.getFullYear()
        const taskDate=new Date(task.starttime)
        var taskYear=taskDate.getFullYear()
        var taskMonth=taskDate.getMonth()+1
        var taskDay=taskDate.getDate()
        const todayOfStr=this.convertDate(new Date())
        var taskDateOfStr=this.convertDate(task.starttime)
        var lastDate
        if(todayOfStr==taskDateOfStr){
          lastDate='今天'
        } else if(taskYear==thisYear){
          lastDate=`${taskMonth}月${taskDay}日`
        }else if(taskDateOfStr=='2999/12/31'){
          lastDate='未定义'
        }else {
          lastDate=`${taskYear}-${taskMonth}-${taskDay}`
        }
        return lastDate
      }
    },

    showTaskTime(){
      return (task)=>{
        
        const today=new Date()
        const thisYear=today.getFullYear()
        const taskDate=new Date(task.starttime)
        var taskYear=taskDate.getFullYear()
        var taskMonth=taskDate.getMonth()+1
        var taskDay=taskDate.getDate()
        const todayOfStr=this.convertDate(new Date())
        var taskDateOfStr=this.convertDate(task.starttime)
        var lastDate
        if(todayOfStr==taskDateOfStr){
          lastDate=true
        } else if(taskYear==thisYear){
          lastDate=true
        }else if(taskDateOfStr=='2999/12/31'){
          lastDate=false
        }else {
          lastDate=true
        }
        return lastDate
      }
    },



    taskList(){
      return this.tasklist
    },


  },

  created() {
    
    //根据历史的被选择项目，获取呈现出对应的任务信息
    // this.getTasks(this.selectedProjectRoute)
    // this.getTasks(this.selectedProject.idfp)
    this.rangeOftask='全项目完成率'

    
    this.updateSelectProjName({name:'今天'})

  },
  mounted(){
    this.username=localStorage.getItem('username')

    this.getMyTaskAclList(this.username)
      .then((result)=>{
        this.myTaskAcllist=result
      })

    // this.$refs.addProjInput.placeholder='添加"今天"的任务至"未分类"'
    this.newStarttimeFromDatePicker=new Date()
    this.runAndMergeArraysOfToday()
  },
  filters: {
    formatDate: function(value) {
      if (!value) return '';
      const date = new Date(value);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      if (year==2999) {
        return ''
      } else return `${year}/${month}/${day}`
    },
    formatPretask: function(value) {
      if(value==='null'){
        return ''
      }else {
        return value
      }
      // return value === null ? 'x':value
    },
  },
  watch:{
    '$route.params.idfp':function(){
      const selectedProj=localStorage.getItem('selectedProjectRouteLocal')
      const id=this.$route.params.idfp
      const username=localStorage.getItem('username')
      const nogroup=`${username}nogroup`
      if(id=="today"){
        const today=new Date()
        today.setHours(0, 0, 0, 0)
        this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
        this.selectedTasks=this.tasklist
        this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
        this.nogroupTasklist = this.tasklistall.filter(task => (task.project === `${username}nogroup`));
        const tasklist1=this.tasklist1Ofmethod()

      }else if(id=="week"){
        const today=new Date()
        today.setHours(0, 0, 0, 0)
        const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
        this.tasklist = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 0);
        });
        
        this.tasklistDone = this.tasklistall.filter(task => {
          const taskStartTime = new Date(task.starttime);
          return (taskStartTime <= oneWeekLater) && (task.done === 1);
        });

        this.nogroupTasklist = this.tasklistall.filter(task => {
          return (task.project === nogroup) && (task.done === 0);
        });
        this.selectedTasks=this.tasklist
      }else if(id=="inbox"){
        // this.getTasks(`${username}nogroup`)
        this.tasklist=this.tasklistall.filter(task=>(task.project==`${username}nogroup`)&&task.done===0)
        this.tasksOfSelectedProject=this.tasklistall.filter(task=>task.project==`${username}nogroup`)
        const countOfDone = this.tasksOfSelectedProject.filter(task => task.done === 1).length
        const countAll=this.tasksOfSelectedProject.length
        this.crOfTasks=(countAll==0)?0:Number((countOfDone/countAll*100).toFixed(0))
        // this.tasklist.forEach((item)=>{
        //   if (!item.starttime) {item.starttime=''}
        //   const date = new Date(item.starttime)
        //   const year = date.getFullYear()
        //   const month = (date.getMonth() + 1).toString().padStart(2, '0')
        //   const day = date.getDate().toString().padStart(2, '0')
        //   if (year==2999) {
        //     item.starttime=''
        //   } else {
        //     item.starttime=`${year}/${month}/${day}`
        //   } 
        // })
        // this.tasklist.forEach((item)=>{
        //   if (item.endtime===null) {
        //     item.endtime=''} else  {
        //       const date = new Date(item.endtime)
        //       const year = date.getFullYear()
        //       const month = (date.getMonth() + 1).toString().padStart(2, '0')
        //       const day = date.getDate().toString().padStart(2, '0')
        //       if (year==2999) {
        //         item.endtime=''
        //       } else {
        //         item.endtime=`${year}/${month}/${day}`
        //       } 
        //     }
            
        // })
        this.selectedTasks=this.tasklist
        this.tasklistDone=this.tasklistall.filter(task=>task.project==nogroup&&task.done===1)
      }
      else {
        // this.getTasks(id)
        this.tasklist=this.tasklistall.filter(task=>(task.project==id)&&task.done===0)
        
        this.tasksOfSelectedProject=this.tasklistall.filter(task=>task.project==id)

        const countOfDone = this.tasksOfSelectedProject.filter(task => task.done === 1).length
        const countAll=this.tasksOfSelectedProject.length
        this.crOfTasks=(countAll==0)?0:Number((countOfDone/countAll*100).toFixed(0))
        this.selectedTasks=this.tasklist
        this.tasklistDone=this.tasklistall.filter(task=>task.project==id&&task.done===1)
      }
    },

    selectedOption: function(newVal, oldVal) {
      switch(newVal) {
        case '全部':
          this.selectedTasks=this.tasklist
          this.tasksOfSelectedProject=this.tasklistall.filter(task=>task.project==this.selectedProject.name)
          const num=this.tasksOfSelectedProject.filter(task => task.done === 1).length
          const countOfDone1 = num>=1?num:0
          const countAll1=this.tasksOfSelectedProject.length
          this.rangeOftask='全项目完成率'
          
          // this.crOfTasks=Number((1/countAll1*100)).toFixed(0)


          break;
        case '今天':
          // const today = new Date().toISOString().split('T')[0]
          this.selectedTasks=this.tasklist.filter(task => new Date(task.starttime)<=new Date())
          const tasksOfDone = this.tasksOfSelectedProject.filter(task => task.done === 1)
          const countDone=tasksOfDone.length
          const countAll=this.tasksOfSelectedProject.length
          const cr=(countDone/countAll).toFixed(0)
          this.rangeOftask='今天完成率'

          break;
        case '本周':
          const today = new Date(); // 获取当前日期和时间
          today.setHours(0,0,0,0)
          const currentDay = today.getDay(); // 获取当前是星期几
          const diff = today.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // 计算本周一的日期
          const monday = new Date(today); // 复制 today 对象的值
          monday.setDate(diff); // 设置 monday 对象的日期
          const sunday = new Date(monday); // 复制 monday 对象的值
          sunday.setDate(monday.getDate() + 6); // 设置 sunday 对象的日期
          this.selectedTasks=this.tasklist.filter(task =>new Date(task.starttime)<=sunday)
          this.rangeOftask='本周完成率'
          break;
        case '本月':
          const today1 = new Date(); // 获取当前日期和时间
          today1.setHours(0, 0, 0, 0);
          const lastDayOfMonth = new Date(today1.getFullYear(), today1.getMonth() + 1, 0); // 获取本月最后一天的日期
          this.selectedTasks = this.tasklist.filter(task => new Date(task.starttime) <= lastDayOfMonth);
          this.rangeOftask='本月完成率'
          break;
        default:
          this.selectedTasks=this.tasklist
          break;
      }
    }
    
  },


  
  methods: {



    tasklist1Ofmethod(){
      const id=this.$route.params.idfp
      console.log('id',id)
      if(id=='today'){
        const today=new Date()
        // let tasklist01=this.selectedTasks.filter(item=>item.parent=='roottask'||
        let tasklist01=this.tasklist.filter(item=>item.parent=='roottask'||
        (item.parent!=='roottask'&&this.tasklistall.find(i=>i.idft==item.parent&&(new Date(i.starttime) > today||i.starttime==''))))
        return tasklist01
      }else if(id=='week') {
        const today=new Date()
        today.setHours(0, 0, 0, 0)
        const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6)
        // let tasklist01=this.selectedTasks.filter(item=>item.parent=='roottask'||
        let tasklist01=this.tasklist.filter(item=>item.parent=='roottask'||
        (item.parent!=='roottask'&&this.tasklistall.find(i=>i.idft==item.parent&&new Date(i.starttime) > oneWeekLater&&i.done===0)))
        return tasklist01
      }else {
        // let tasklist01 = this.selectedTasks.filter(
          let tasklist01 = this.tasklist.filter(
        function(task) {
          return task.level === 1&&task.done==0
        })
        return tasklist01
      }

    },

    showDateWithNoTime(dateTime){
      let showDate
      if(!dateTime) {
        showDate=''
      } 
      const date = new Date(dateTime)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      if (year==2999) {
        showDate=''
        } else {
          showDate=`${year}/${month}/${day}`
        } 
      return showDate
    },

    //合并两个数组的函数
    mergeArrays(A, B) {
      if (!A) {
        A = [];
      }
      if (!B) {
        B = [];
      }

      const mapA = new Map();
      A.forEach(item => {
        mapA.set(item.idft, item);
      });
    
      const result = A.slice();
    
      B.forEach(item => {
        if (!mapA.has(item.idft)) {
          result.push(item);
        }
      });
    
      return result;
    },
    doneTaskFirst (doneTasks) {
      const doneTasks1 = [];
      for (var i = 0; i < doneTasks.length; i++) {
        if (doneTasks[i].level === 1) {
          doneTasks1.push(doneTasks[i]);
        } else {
          const parentTask = doneTasks.find(task => task.idft === doneTasks[i]?.parent);
          if (!parentTask) {
            doneTasks1.push(doneTasks[i]);
          }
        }
      }
      return doneTasks1;
    },


    //以下是与任务右键编辑菜单有关的方法
    // taskEditMenu(event,task){
    //       event.preventDefault()
    //       event.stopPropagation()
    //       this.showTaskEdit=true
    //       this.switchMenuMask()
    //       this.selectedTask=task
    //       const top=(window.innerHeight-event.clientY)<230?(event.clientY-230):event.clientY+10
    //       const left=(window.innerWidth-event.clientX)<112?(event.clientX-112):event.clientX+10
    //       this.parentNode ={clientY:top,clientX:left}
    //       eventBus.$on('closeContexMenu', () => {
    //       this.showTaskEdit=false
    //       })
    // },

    async getProjectPartersFromTask(task) {
      try {
        // 根据选择的任务，获取任务所在项目的参与者信息（用户名、昵称、头像）
        const selectedProjectAcls = await this.getProjectAclListByIdfp(task.project);
        const promises = selectedProjectAcls.map(async (projectacl) => {
          const result = await this.getUserInfoByUsername(projectacl.username);
          const avatarUrl = await this.showPic(result.avatar);
          const nickname = result.nickname;
          projectacl.avatarUrl = avatarUrl;
          projectacl.nickname = nickname;
        });
        await Promise.all(promises);
        this.selectedProjectAcls = selectedProjectAcls;
        return selectedProjectAcls
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },

    async getSelectedTaskAcls() {
      let selectedTaskAcls = [];  
      // 构建任务关系数组
      this.selectedProjectAcls.forEach((item) => {
        const taskAcl = {
          idftacl: uuidv4(),
          username: item.username,
          nickname: item.nickname,
          permit_level: item.permit_level,
          project_idfp: item.project_idfp,
          avatarUrl: item.avatarUrl,
          task_idft: this.selectedTask.idft
        };
        selectedTaskAcls.push(taskAcl);
      });

      try {
        // 获取指定任务ID的任务关系
        const results = await this.getTaskAclsByTaskIdft(this.selectedTask.idft);
    
        // 根据结果更新任务关系数组
        results.forEach((result) => {
          // 查找是否存在相同的 username
          const existingAclIndex = selectedTaskAcls.findIndex((acl) => acl.username === result.username);
          if (existingAclIndex !== -1) {
            // 如果存在相同的 username，则用 result 替换该元素
            result.avatarUrl = selectedTaskAcls[existingAclIndex].avatarUrl;
            selectedTaskAcls[existingAclIndex] = result;
          }
        });
    
        this.selectedTaskAcls = selectedTaskAcls
        console.log('ceshi',selectedTaskAcls)
        return selectedTaskAcls
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    },

    taskEditMenu(event,task){
      this.usernogroup=`${this.username}nogroup`
      this.selectedTask=task      
      //获取选中的任务对应的所有关系
      // this.selectedProjectAcls=this.selectedProjectParter
      if(this.selectedProject.permit_level=='charger') {
        this.selectedTask.permit_level='charger'
      } else {
        const taskAcl=this.myTaskAcllist.filter(item=>item.task_idft==task.idft)
        this.selectedTask.permit_level=taskAcl.length==0?'viewer':(taskAcl[0].permit_level=='charger'?'charger':'viewer')
      }
      event.preventDefault()
      event.stopPropagation()
      this.getProjectPartersFromTask(task)
        .then((result)=>{     
          this.getSelectedTaskAcls()
            .then((result)=>{
              this.showTaskEdit=true
              
              this.switchMenuMask()
              const top=(window.innerHeight-event.clientY)<230?(event.clientY-230):event.clientY+10
              const left=(window.innerWidth-event.clientX)<112?(event.clientX-112):event.clientX+10
              
              this.parentNode ={clientY:top,clientX:left}
              eventBus.$on('closeContexMenu', () => {
              this.showTaskEdit=false
              })
            })
        
        })
    },


    cancelEditTask(){

    },
    closeEditTask(){

    },
    deleteTask(){
      this.tasklist=this.tasklist.filter(item=>item.idft!==this.selectedTask.idft)
      this.deleteTaskbyIdft(this.selectedTask.idft)
      this.showTaskEdit=false
    },

    updateTaskAcl(taskAcl){
      this.updateTaskAclByUsernameAndIdft(taskAcl)
      this.myTaskAcllist = this.myTaskAcllist.map(item => {  
        if (item.username === taskAcl.username) {
          return {
            ...item,
            permit_level: taskAcl.permit_level
          };
        }
        return item;
        });
    },

    taskeditMenuitemClicked(item){
      
      switch (item.id){
        case 1: {
          if(this.selectedTask.level<6) {
            const id=this.$route.params.idfp
            const today=this.convertDate(new Date())
            const starttime=(id=='today'||id=='week')?today:'2999/12/31'
            this.selectedTask.haschild=1
            const idft=uuidv4()
            const childTask={
              idft: idft,
              name:'',
              level: this.selectedTask.level+1,
              charger: this.selectedTask.charger,
              parent:this.selectedTask.idft,
              starttime: starttime,
              endtime: starttime,
              pretask:'',
              haschild: null,
              expanded: 1,
              done: 0,
              project: this.selectedTask.project
            }
            this.tasklist.unshift(childTask)
            this.tasklistall.push(childTask)
            // this.addTaskSql(childTask)
            this.addTaskToSql(childTask)
            this.selectedTask.starttime=this.convertDate(this.selectedTask.starttime)
            this.updateTaskOfSql(this.selectedTask)
            const newTaskAcl={idftacl:uuidv4(),permit_level:'charger',username:this.username,task_idft:idft}
            console.log('newTaskAcl',newTaskAcl)
            this.myTaskAcllist.push(newTaskAcl)
          }
          break
        }
        case 2: {
          console.log('2',this.selectedProjectRoute)
          break
        }
        case 3: {
          console.log(this.selectedProject)
          break
        }
        case 8: {
          // this.tasklist=this.tasklist.filter(item=>item.idft!==this.selectedTask.idft)
          // this.deleteTaskbyIdft(this.selectedTask.idft)
          // this.delChildTask(this.tasklist,this.selectedTask)
          // break

          this.showTaskEdit=false
          if(this.selectedTask.permit_level==='charger') {

            this.tasklist=this.tasklist.filter(item=>item.idft!==this.selectedTask.idft)
            this.tasklistall=this.tasklistall.filter(item=>item.idft!==this.selectedTask.idft)
            this.deleteTaskbyIdft(this.selectedTask.idft)
            this.delChildTask(this.tasklist,this.selectedTask)
          } else {
            alert('您不具有删除权限！')
          }
          break
        }}
        this.showTaskEdit=false
    },

    delChildTask(tasks, parentTask) {
      let hasChildTasks = false;
      tasks.forEach((task) => {
        if (task.parent === parentTask.idft) {
          this.deleteTaskbyIdft(task.idft);
          const leftChildTasks = tasks.filter((item) => item.parent === task.idft);
          if (leftChildTasks.length > 0) {
            hasChildTasks = true;
            this.delChildTask(tasks, task);
          }
        }
      });
      if (!hasChildTasks) {
        return;
      }
    },
    //以上是与任务右键编辑菜单有关的方法

   //以下是任务增删改查相关操作的函数
   handleKeyDown(event,task) {
      if (event.target.value === '') {
        this.deleteTaskbyIdft(task.idft)
        this.delChildTask(this.tasklist,task)
      }
    },
   
    

    //以下是与任务时间编辑相关的方法


    //以下是任务详情相关的操作
    upDateStarttime(value){
      const starttime=this.convertDate(value)
      this.selectedTask.starttime=starttime
      this.updateTaskOfSql(this.selectedTask)
    },

    updateTaskdate(task) {
      this.$nextTick(function (task) {
        console.log('更改任务开始事件1',task)
      })
    },

    updateTaskContent(item){
      const targetIndex=this.taskContents.findIndex(i=>i.idfc==item.idfc)
      this.taskContents[targetIndex]=item
      this.updateTaskContentToSql(item)
      
    },


    //以下是数据库操作相关的方法
    getTasks(project){
      this.getUsername()
      const token = localStorage.getItem('token')
      const username=localStorage.getItem('username')
      const user={charger: username}
      axios({
          method:'get',
          url: 'https://api2.itaskid.com' + '/my/tasklist',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: user
          })
        .then((response)=>{
          if (Array.isArray(response.data)){
            this.tasklistall=response.data.reverse()
            const today=new Date()
            today.setHours(0, 0, 0, 0)
            if(project=='today'){
              this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
              this.selectedTasks=this.tasklist
              this.tasklistDone=this.tasklistall.filter(task=>(task.project==`${username}nogroup`)&&task.done===1)
            }else if (project=='week') {
              const today=new Date()
              today.setHours(0, 0, 0, 0)
              const oneWeekLater = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6);
              this.tasklist=this.tasklistall.filter(task=>(JSON.stringify(new Date(task.starttime)&&task.done===0)
              ==JSON.stringify(oneWeekLater))||((new Date(task.starttime)<oneWeekLater)&&(task.starttime)))
              this.nogroupTasklist=this.tasklistall.filter(task=>(task.project==`${username}nogroup`)&&task.done===0)
              this.tasklistDone=this.tasklistall.filter(task=>(task.project==`${username}nogroup`)&&task.done===1)
              this.selectedTasks=this.tasklist
            }else {
              this.tasklist=this.tasklistall.filter(task=>task.project==project&&task.done===0)
              this.tasksOfSelectedProject=this.tasklistall.filter(task=>task.project==project)

              const countOfDone = this.tasksOfSelectedProject.filter(task => task.done === 1).length
              const countAll=this.tasksOfSelectedProject.length
              this.crOfTasks=(countAll==0)?0:Number((countOfDone/countAll*100).toFixed(0))
              

              this.tasklist.forEach((item)=>{
                if (!item.starttime) {item.starttime=''}
                const date = new Date(item.starttime)
                const year = date.getFullYear()
                const month = (date.getMonth() + 1).toString().padStart(2, '0')
                const day = date.getDate().toString().padStart(2, '0')
                if (year==2999) {
                  item.starttime=''
                } else {
                  item.starttime=`${year}/${month}/${day}`
                } 
              })
              this.tasklist.forEach((item)=>{
                if (item.endtime===null) {
                  item.endtime=''} else  {
                    const date = new Date(item.endtime)
                    const year = date.getFullYear()
                    const month = (date.getMonth() + 1).toString().padStart(2, '0')
                    const day = date.getDate().toString().padStart(2, '0')
                    if (year==2999) {
                      item.endtime=''
                    } else {
                      item.endtime=`${year}/${month}/${day}`
                    } 
                  }
                  
              })
              this.selectedTasks=this.tasklist
              this.tasklistDone=this.tasklistall.filter(task=>task.project==project&&task.done===1)
            }
            console.log('获取任务列表成功！')
          }else
          alert('账号未认证，无法获取数据getTasks！')
        })
        .catch(function (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        })
      },


    //以下是通过任务权限获取任务信息
    async getTasksByTaskacl() {
      try {
        const username = localStorage.getItem('username');
        const nogroup = `${username}nogroup`;
        let myTaskAcllist = [];
        const result = await this.getMyTaskAclList(username);
        if (result.length > 0) {
          myTaskAcllist = result;
          const promises = myTaskAcllist.map(async (taskacl) => {
            const task = await this.getTaskinfoByIdft(taskacl.task_idft);
            taskacl.name = task[0].name;
            taskacl.duration = task[0].duration;
            taskacl.endtime = task[0].endtime;
            taskacl.pretask = task[0].pretask;
            taskacl.level = task[0].level;
            taskacl.parent = task[0].parent;
            taskacl.project = task[0].project;
            taskacl.haschild = task[0].haschild;
            taskacl.done = task[0].done;
            taskacl.expanded = task[0].expanded;
            taskacl.id = task[0].id;
            taskacl.delete = task[0].delete;
            taskacl.starttime = task[0].starttime;
            taskacl.idft = task[0].idft;
            taskacl.record = task[0].record
            return taskacl
          });
          // console.log('myTaskAcllist3',myTaskAcllist)
          // return myTaskAcllist
          // myTaskAcllist= await Promise.all(promises);
        }
        return myTaskAcllist
      } catch (error) {
        console.log(error?.response?.data?.message || 'An error occurred.');
      }
    },


    // 调用两个异步函数并合并数组,并初始化任务数据为今天的任务
    async runAndMergeArraysOfToday() {
      const myTaskAcllist = await this.getTasksByTaskacl()
      var tasklistByProjectAcl=[]
      this.getMyProjectAclList(this.username)
        .then((result)=>{
          const n=result.length
          var i
          var promises = []
          for(i=0;i<n;i++){
            if(!result[i].permit_level=='parter'){
              var promise=this.getTasksByIdfp(result[i].project_idfp)
              promises.push(promise)
            }
          }
          return Promise.all(promises)
        })
        .then((results) => {
          results.forEach((result) => {
            tasklistByProjectAcl=tasklistByProjectAcl.concat(result)
          })
          const mergedArray = this.mergeArrays(myTaskAcllist, tasklistByProjectAcl);
          this.tasklistall=mergedArray 
          this.tasklistall.forEach((item)=>{
            if (!item.starttime) {item.starttime=''}
            const date = new Date(item.starttime)
            const year = date.getFullYear()
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const day = date.getDate().toString().padStart(2, '0')
            if (year==2999) {
              item.starttime=''
            } else {
              item.starttime=`${year}/${month}/${day}`
            } 
          })

          this.tasklistall.forEach((item)=>{
            if (item.endtime===null) {
              item.endtime=''} else  {
                const date = new Date(item.endtime)
                const year = date.getFullYear()
                const month = (date.getMonth() + 1).toString().padStart(2, '0')
                const day = date.getDate().toString().padStart(2, '0')
                if (year==2999) {
                  item.endtime=''
                } else {
                  item.endtime=`${year}/${month}/${day}`
                } 
              }
          })

          


          const today = new Date();
          today.setHours(0, 0, 0, 0);
          this.tasklist=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===0)
          this.tasklistDone=this.tasklistall.filter(task=>(new Date(task.starttime))<=today&&task.done===1)
          const username=localStorage.getItem('username')
          this.nogroupTasklist = this.tasklistall.filter(task => (task.project === `${username}nogroup`));

          // this.$router.push({
          //   name: 'plan/project',
          //   params: {
          //     idfp: 'today'
          //   }
          // }).catch(err => {
          //   if (err.name !== 'NavigationDuplicated') {
          //     throw err
          //   }
          // })
      })

      
    },

    // 调用两个异步函数并合并数组
    async runAndMergeArrays() {
      const myTaskAcllist = await this.getTasksByTaskacl();
      
      
      var tasklistByProjectAcl=[]
      this.getMyProjectAclList(this.username)
        .then((result)=>{
          const n=result.length
          var i
          var promises = []
          for(i=0;i<n;i++){
            var promise=this.getTasksByIdfp(result[i].project_idfp)
            promises.push(promise)
          }
          return Promise.all(promises)
        })
        .then((results) => {
          results.forEach((result) => {
            console.log('resulty',result)
            tasklistByProjectAcl=tasklistByProjectAcl.concat(result)
          })
          const mergedArray = this.mergeArrays(myTaskAcllist, tasklistByProjectAcl);
          this.tasklistall=mergedArray 


          this.tasklistall.forEach((item)=>{
            if (!item.starttime) {item.starttime=''}
            const date = new Date(item.starttime)
            const year = date.getFullYear()
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const day = date.getDate().toString().padStart(2, '0')
            if (year==2999) {
              item.starttime=''
            } else {
              item.starttime=`${year}/${month}/${day}`
            } 
          })
          
          this.tasklistall.forEach((item)=>{
            if (item.endtime===null) {
              item.endtime=''} else  {
                const date = new Date(item.endtime)
                const year = date.getFullYear()
                const month = (date.getMonth() + 1).toString().padStart(2, '0')
                const day = date.getDate().toString().padStart(2, '0')
                if (year==2999) {
                  item.endtime=''
                } else {
                  item.endtime=`${year}/${month}/${day}`
                } 
              }
          })

        })

    },

    ...mapMutations({
      updateSelectProjName:'updateSelectProjName',
      updateUser: 'updateUser',
      switchMenuMask: 'switchMenuMask',
    }),

    ...mapActions({
      addTaskToSql:'addTaskToSql',
      getMyTaskAclList:'getMyTaskAclList',
      getTaskinfoByIdft: 'getTaskinfoByIdft',
      // getUserInfoBySearchText:'getUserInfoBySearchText',
      showPic:'showPic',
      getTasksByIdfp:'getTasksByIdfp',
      // showTimePub:'showTimePub',
      // addTaskAclToSql:'addTaskAclToSql',
      getMyProjectAclList:'getMyProjectAclList',
      deleteTaskAclbyIdft:'deleteTaskAclbyIdft',
      getTaskAclsByTaskIdft:'getTaskAclsByTaskIdft',
      updateTaskAclByUsernameAndIdft:'updateTaskAclByUsernameAndIdft',
      getProjectAclListByIdfp:'getProjectAclListByIdfp',
      getUserInfoByUsername:'getUserInfoByUsername',
    }),
    
    goToTaskDetail(e,task){

      this.selectedTask=task
      // e.stopPropagation()
      localStorage.setItem('selectedTaskLocal',task.name)
      const oldtask=localStorage.getItem('selectedTaskLocal')
      this.$router.push({
        name: 'detail',
        params: {
          idft:task.idft,
          name: task.name
        }
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })


    },

    addTaskFromInput(){
      const nogroup=`${this.username}nogroup`
      const id=this.$route.params.idfp
      if(this.newTaskNameLv1.trim()!==''){
        const id=this.$route.params.idfp
        let newTask={}
        if(id=='today'||(id=='week')) {
          const today=new Date()
          const starttime=this.convertDate(today)
          newTask={
            name:this.newTaskNameLv1,
            duration: null,
            starttime: starttime,
            endtime: '2999/12/31',
            level:1,
            parent: 'roottask',
            project:nogroup,
            haschild:0,
            done:0,
            expanded:1,
            charger:this.username,
          }
        } else if(id=='inbox'){
          newTask={
            name:this.newTaskNameLv1,
            duration: null,
            starttime: '2999/12/31',
            endtime: '2999/12/31',
            level:1,
            parent: 'roottask',
            project:nogroup,
            haschild:0,
            done:0,
            expanded:1,
            charger:this.username,
          }
        }
        else {
          newTask={
            name:this.newTaskNameLv1,
            duration: null,
            starttime: '2999/12/31',
            endtime:'2999/12/31',
            level:1,
            parent: 'roottask',
            project:this.selectedProjectRoute,
            haschild:0,
            done:0,
            expanded:1,
            charger:this.username,
          }

          
        }

        const oldTasks=[...this.tasklist]
        this.tasklist.unshift(newTask)
        this.nogroupTasklist.unshift(newTask)
        this.addTaskToSql(newTask)
          .then(()=>{
            this.runAndMergeArrays()
          })
        this.newTaskNameLv1=''
      }
    },
   
  
    toggleNode(event,task){
      event.stopPropagation()
      task.expanded=!task.expanded
    },

    
    addBroTask(e,task) {
      if(task.name.trim()!==''){
        const username=localStorage.getItem('username')
        const nogroup=`${username}nogroup`
        const id=this.$route.params.idfp
        var newTask={}
        if(id=='today'||(id=='week')) {
          const today=new Date()
          const starttime=this.convertDate(today)
          newTask={
            name:'',
            duration: 1,
            starttime: starttime,
            endtime: starttime,
            pretask:'',
            level:task.level,
            parent: task.parent,
            haschild:0,
            done:0,
            expanded:1,
            charger:username,
            project: task.project
          }
        } else {
          newTask={
            name:'',
            duration: 1,
            starttime: '2999/12/31',
            endtime:'2999/12/31',
            pretask:'',
            level:task.level,
            parent: task.parent,
            project:task.project,
            haschild:0,
            done:0,
            expanded:1,
            charger:username,
          }
        }
        const taskIndex=this.tasklist.findIndex(item=>item.idft===task.idft)
        this.tasklist.splice(taskIndex+1,0,newTask)
        this.addTaskToSql(newTask)
      }
      
    },
    
    //以下是与任务详情相关的函数
    addTaskContent(item){
      const targetIndex=this.taskContents.findIndex(i=>i.idfc==item.idfc)
      const idfc=uuidv4()
      const taskid=this.selectedTask.idft
      const newContent={taskid:taskid, idfc:idfc,content:''}
      this.taskContents.splice(targetIndex+1,0,newContent)
      // this.taskContents.push(newContent)
      this.addTaskContentToSql(newContent)
      // this.updateTaskContents(this.taskContents)
    },
    

    // 数据库tasklist表的增删改查
    getUsername() {
      const token = localStorage.getItem('token')
      axios.get('https://api2.itaskid.com' + '/api/getuserinfo', { 
        headers: { Authorization: token} // 在 headers 中添加 Authorization Token
      })
      .then(response => {
        this.username = response.data.username;
        const user={username: this.username}
        this.updateUser(user)
      })
      .catch(error => {
        console.log(error?.response?.data?.message || 'An error occurred.');
      });
    },

    // 添加某个任务
    addTaskSql(newTask){
      const token = localStorage.getItem('token')
      const idft=uuidv4()
      newTask.idft=(newTask.idft==undefined)?idft:newTask.idft
      newTask.starttime=(newTask.starttime=='')?'2999/12/31':this.convertDate(newTask.starttime)
      newTask.endtime=(newTask.endtime=='')?'2999/12/31':this.convertDate(newTask.endtime)
      axios({
        method:'post',
        url: 'https://api2.itaskid.com' + '/my/tasklist',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: newTask
        })
        .then((response)=>{
          console.log('添加任务成功')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
        })

    },

    //根据idft删除数据库中的数据
    deleteTaskbyIdft(idft){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: 'https://api2.itaskid.com' + '/my/tasklist/deletebyidft',
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: {idft:idft}
        })
        .then((response)=>{
          this.deleteTaskAclbyIdft(idft)
          console.log('删除任务成功！')
        })
        .catch(error => {
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除任务失败！')
        })
        this.switchMenuMask()
    },


    //删除某个任务
    deleteTaskSql(url,data,tasks){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        // url: 'https://api2.itaskid.com' + '/my/tasklist',
        url: url,
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // data: {sqlStr: sqlStr, targetArray: targetArray}
        data: data
        })
        .then((response)=>{
          console.log('删除任务成功！')
          for(var i=0;i<tasks.length;i++){
            this.addTaskSql(tasks[i])
          }
          // this.addTasksRecursively(tasks, 0)
        })
        .catch(error => {
          // alert(error.response.data.message)
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除任务失败！')
        })
    },
    

    //通过parent属性删除任务并更新
    deleteTaskSqlByParent(url,data,tasks){
      const token = localStorage.getItem('token')
      axios({
        method:'delete',
        url: url,
        headers: {
          Authorization: token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
        })
        .then((response)=>{
          console.log('删除任务成功！')
          for(var i=0;i<tasks.length;i++){
            this.addTaskSql(tasks[i])
          }
        })
        .catch(error => {
          // alert(error.response.data.message)
          alert(error?.response?.data?.message || 'An error occurred.')
          console.log('删除任务失败！')
        })
    },

    addTasksRecursively(tasks, index) {
      // 递归终止条件：当索引超出任务数组的长度时，停止递归
      if (index >= tasks.length) {
        console.log("所有任务添加完成");
        return;
      }
      // 添加当前索引对应的任务
      this.addTaskSql(tasks[index]);
      // 递归调用自身，将索引加一，继续添加下一个任务
      addTasksRecursively(tasks, index + 1);
    },
    

    updateTaskOfSql(task){
      task.starttime=(task.starttime=='')?'2999/12/31':this.convertDate(task.starttime)
      task.endtime=(task.endtime=='')?'2999/12/31':this.convertDate(task.endtime)
      const token = localStorage.getItem('token')
      axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/tasklist',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: task
          })
          .then((response)=>{
            // console.log(response.data)
            console.log('修改任务成功',response.data)
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
    },

    // //以下是任务详情相关的增删改查的函数
    getTaskContents(task){
      const token = localStorage.getItem('token')
      const username=localStorage.getItem('username')
      const user={charger: username}
      axios({
          method:'get',
          url: 'https://api2.itaskid.com' + '/my/taskcontents',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: {taskid: task.idft}
          })
        .then((response)=>{
          if (Array.isArray(response.data)){
            this.taskContents=response.data
          }else
          alert('账号未认证，无法获取数据！')
        })
        .catch(function (error) {
          console.log(error?.response?.data?.message || 'An error occurred.');
        })
      },

      addTaskContentToSql(newContent){
        const token = localStorage.getItem('token')
        axios({
          method:'post',
          url: 'https://api2.itaskid.com' + '/my/taskcontents',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newContent
          })
          .then((response)=>{
            
            console.log('添加任务内容成功')
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
      },

    //   insertTaskContentToSql(newContent){
    //     const token = localStorage.getItem('token')
    //     axios({
    //       method:'delete',
    //       url: 'https://api2.itaskid.com' + '/my/taskcontents',
    //       headers: {
    //         Authorization: token,
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //       },
    //       data: {taskid: newContent.taskid}
    //       })
    //       .then((response)=>{
    //         console.log('删除所有任务内容成功',response.data)
    //         for(var i=0;i<this.taskContents.length;i++){
    //           this.addTaskContentToSql(this.taskContents[i])
    //         }
    //       })
    //       .catch(error => {
    //         alert(error?.response?.data?.message || 'An error occurred.')
    //       })
          
    //   },

      updateTaskContentToSql(newContent){
        const token = localStorage.getItem('token')
        axios({
          method:'put',
          url: 'https://api2.itaskid.com' + '/my/taskcontents',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: newContent
          })
          .then((response)=>{
            

            console.log('修改任务内容内容成功')
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
      },
      
      deleteTaskContentsBytaskid(task){
        const token = localStorage.getItem('token')
        const data={taskid: task.idft}
        axios({
          method:'delete',
          url: 'https://api2.itaskid.com' + '/my/taskcontents',
          headers: {
            Authorization: token,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: data
          })
          .then((response)=>{
            
            console.log('删除任务内容详情成功',response.data)
          })
          .catch(error => {
            alert(error?.response?.data?.message || 'An error occurred.')
          })
      },      
    // //以上是任务相关的数据库增删改查

    updateTasksSqlByProjectAndParent(newTasks, project,parent) {
    
      if(newTasks.length>1) {
        const id=newTasks[newTasks.length-1].id
        const data={project:project, parent: parent}
        const url='https://api2.itaskid.com' + '/my/tasklist/deltaskbyprojectandparent'
        this.deleteTaskSql(url,data,newTasks)
      }else {
        for(var i=0;i<newTasks.length;i++){
        this.addTaskSql(newTasks[i])
      }
      } 
    },

    updateTasksSqlByParent(newTasks, parent) {

  
      if(newTasks.length>1) {
        const id=newTasks[newTasks.length-1].id
        const data={parent:parent}
        const url='https://api2.itaskid.com' + '/my/tasklist/deltaskbyparent'
        this.deleteTaskSql(url,data,newTasks)
      }else {
        for(var i=0;i<newTasks.length;i++){
        this.addTaskSql(newTasks[i])
      }
      } 
    },


    //把标准时间转换成普通的字符串时间
    convertDate(targetDate){
      const date = new Date(targetDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      
      const formattedDate = `${year}/${month}/${day}`;
      return formattedDate
      
    },

    //以下是与完成任务相关的函数
    showDoneTaskToggle(){
      this.showDoneProject=!this.showDoneProject
    },

    toggleTaskDone(e,task){
      if(e.target.checked) {
        this.doneTask(task)
      } else {
        task.done=0
        this.updateTaskOfSql(task)
      }
    },

    doneTask(task){
      task.done=1
      this.tasklistDone=this.tasklistall.filter(item=>item.done===1&&item.project==task.project)
      this.updateTaskOfSql(task)
      if(task.level<6){
        this.tasklist.forEach((item)=>{
          if(item.parent===task.idft){
            this.doneTask(item)
          }else {
            return
          }
        })
      }
    },
  
    toggleTaskUndone(e,task){
      if(!e.target.checked) {
        this.undoneTask(task)
      } else {
        task.done=1
        this.updateTaskOfSql(task)
      }      
    },
    undoneTask(task){
      task.done=0
      this.tasklistDone=this.tasklistDone.filter(item=>item.done==1)
      this.tasklist=this.tasklistall.filter(item=>item.project===task.project&&item.done==0)
      this.updateTaskOfSql(task)
      if(task.level>1){
        this.tasklistDone.forEach((item)=>{
          if(item.idft===task.parent){
            this.undoneTask(item)
          }else {
            return
          }
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.router-link:hover {
  text-decoration: none;
}

h3  {
  height: 30px;
  margin: 10px 0 10px 20px;
}

.input-add-proj {
  float: left;
  text-align: left; 
  margin-left:10px;
  margin-bottom: 10px;
  padding-left:10px;
  width: 300px;
  height: 30px;
  border: none;
  background-color: rgba(250, 250, 250, 0.9);
  border-radius: 5px;
}


input.input-add-proj:focus {
  outline: 0.5px solid rgba(64, 158, 255,0.7) !important;
}
.project-container-leftcol {
  // width: 300px;
  overflow: auto;
  // height: 100vh;
  // border-right: 1px solid rgba(200, 200, 200, 0.9);

  .task-container {
    height: 30px;
    display: flex;
    
    .el-icon-arrow-right {
      line-height: 5px;
    }

    .el-icon-arrow-down {
      line-height: 5px;
    }    

    .tasktime-container {
      display: inline;
      height: 30px;
      line-height: 30px;
    }

    .my-date-picker {
      width: 100px;
      height: 30px;
    }

    .taskinfo-container {
      // width: 280px;
      line-height: 30px;
      height: 30px;
      text-align: justify;;
      box-sizing: border-box;
      display: inline flex;
      // border-bottom: 1px solid gray;
      // border-bottom: 1px solid rgba(200, 200, 200, 0.8);
      white-space: nowrap;
      overflow: hidden;
    }
    
    // margin-right:5px;
    .input-taskname { 
      text-align: left;
      flex: 1;
      float: left;
      line-height: 29px;
      height: 29px;
      background-color: transparent;
      border: none;
      outline:none;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 5px;
      padding: 0px !important;
      border-bottom: 1px solid rgba(200, 200, 200, 0.9);
      
    }
    .task-starttime {
      height: 30px;
      line-height: 30px;
      float: right;
      flex: 0 0 auto;
      text-align: right;
      border-bottom: 1px solid rgba(200, 200, 200, 0.9);
      font-size: 12px;
      color: rgb(71, 114, 250)
      
    }
    
    input {
      // border: none;
      box-sizing: border-box;
      float: right;
      padding: 0;
      margin: 0;
      margin-bottom: -2px;
      border: 0;
      border-bottom: 1px solid grey;
      text-align: center;

      line-height: 29px;
      height: 29px;
      background-color: transparent;
      border: none;
      outline:none;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 0px;
      padding: 0px !important;
      border-bottom: 1px solid rgba(200, 200, 200, 0.9);
      
    }
    .input-taskpretask {
      width: 100px;
    }
    .input-taskendtime {
      width: 150px;
      vertical-align: bottom;

    }
    .input-taskstarttime {
      width: 150px;
    }  
    .input-taskduration {
      width: 60px;

    }
  }
  .task-container:hover {
    // box-shadow: 0 0 5px 1px rgba(3, 3, 160, 0.3);
    background-color: rgb(200, 200, 200,0.3);
  }
  .task-done {
    margin: 3px;
  }

  button {
    width: 13px;
    height: 13px;
    padding: 0;
    margin: 3px
  }
  .task-title {
    line-height: 30px;
    display: inline-block;
    text-align: left;
    border-bottom: 0.5px solid rgba(200, 200, 200, 0.9);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .task-title:focus {
    outline: none;
  }



  ul {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
    margin: 0;
  }

  li {
    list-style: none;
    padding-inline-start: 10px;
    
  }
  // li:hover {
  //   background-color: rgb(200, 200, 200);
  // }

  button {
    border:none;
    background: none;
    color: gray;
  }
  button:hover {
    color: black;
  }
  .routerlink {
    height: 30px;
  }

  .my-date-picker {
    width: 0;
    height: 0;
    overflow: hidden;
    background: transparent;
  }
}

  
.projectdone-container {
  text-align: left;
  color: gray;
  .input-taskname {
    color: gray;
  }
  .task-done:checked input {
    background-color: gray !important;
  }
}
.alltasks-container {
  height: calc(100vh - 200px);
  overflow: auto;
}
</style>